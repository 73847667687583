/* eslint-disable no-unused-expressions */
import Backbone from 'backbone'

const { $ } = Backbone

export default {
  command(html, locales) {
    return {
      run(editor, sender) {
        sender && sender.set('active', true)
        const config = editor.Config
        const pfx = config.stylePrefix
        const tm = editor.TraitManager
        const confTm = tm.getConfig()
        let panelC
        if (confTm.appendTo) return
        if (!this.$cn) {
          this.$cn = $('<div></div>')
          this.$cn2 = $('<div></div>')
          this.$cn.append(this.$cn2)
          this.$cn2.append(
            `<div class="${pfx}traits-label">${locales.config}</div>`,
          )
          this.$cn2.append(html)
          const panels = editor.Panels

          if (!panels.getPanel('views-container')) { panelC = panels.addPanel({ id: 'views-container' }) } else panelC = panels.getPanel('views-container')

          panelC
            .set('appendContent', this.$cn.get(0))
            .trigger('change:appendContent')
        }
        this.$cn2.show()
      },

      stop() {
        this.$cn2 && this.$cn2.hide()
      },
    }
  },
}
