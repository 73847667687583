/* eslint-disable no-param-reassign */
/* eslint-disable arrow-body-style */
/* eslint-disable no-undef */
/* eslint-disable func-names */

/* eslint-disable consistent-return */

const getForms = (r, options, levels = []) => {
  if (r.id === 0 && r.forms && Array.isArray(r.forms)) {
    options = [...options, ...(r.forms.map(f => {
      return { value: f.link, name: f.name, type: 'option' }
    }))]
  } else {
    const child = {
      value: r.id, type: 'group', name: `${levels.join('')}${r.name}`, children: [],
    }
    if (r.forms && Array.isArray(r.forms)) {
      child.children = [...child.children, ...(r.forms.map(f => {
        return { value: f.link, name: `${levels.join('')}${f.name}`, type: 'option' }
      }))]
    }
    if (r.children && Array.isArray(r.children) && r.children.length) {
      levels.push('&nbsp;')
      r.children.forEach(ch => {
        child.children = getForms(ch, child.children, levels)
      })
    }

    options.push(child)
  }

  return options
}

export default function (editor, opt = {}) {
  const c = opt
  const domc = editor.DomComponents
  const defaultType = domc.getType('default')
  const defaultModel = defaultType.model
  const defaultView = defaultType.view
  const FORMWERK_TYPE = 'formwerk'
  const forms = editor.editor.attributes.formwerkForms
  domc.addType(FORMWERK_TYPE, {

    model: defaultModel.extend({
      defaults: {
        ...defaultModel.prototype.defaults,
        formUrl: c.formUrl,
        droppable: false,
        traits: [{
          label: 'Formular',
          name: 'formUrl',
          changeProp: 1,
          type: 'select-with-group',
          options: [{ value: '', type: 'option', name: 'Form auswählen' }, ...(forms || []).reduce((options, r) => {
            return getForms(r, options)
          }, [])],
        }],
        script() {
          const formwerkElment = this
          const formUrl = '{[ formUrl ]}'
          if (formUrl && formUrl !== '-1') {
            formwerkElment.innerHTML = `<iframe
                    src = "${formUrl}"
                    style = "width: 100%; height: 100%; border: none;"
                      ></iframe > `
          } else {
            formwerkElment.innerHTML = 'Bitte ein Formular aus der Liste auswählen'
          }
        },
      },
    }, {
      isComponent(el) {
        if (el.getAttribute
          && el.getAttribute('data-gjs-type') === FORMWERK_TYPE) {
          return {
            type: FORMWERK_TYPE,
          }
        }
      },
    }),

    view: defaultView.extend({
      init() {
        this.listenTo(this.model, 'change:formUrl', this.updateScript)
        const comps = this.model.get('components')

        // Add a basic formwerk template if it's not yet initialized
        if (!comps.length) {
          comps.reset()

          // comps.add('<span data-js="formwerk"><span data-js="formwerk-element"></span></span>')
        }
      },
    }),
  })
}
