/* eslint-disable func-names */
import {
  formwerkRef,
} from './consts'

export default function (editor, opt = {}) {
  const c = opt
  const bm = editor.BlockManager
  const pfx = c.formwerkClsPfx
  const style = c.defaultStyle ? `<style>
    .${pfx} {
      text-align: center;
      font-family: Helvetica, serif;
    }

    .${pfx}-block {
      display: inline-block;
      margin: 0 10px;
      padding: 10px;
    }

    .${pfx}-digit {
      font-size: 5rem;
    }

    .${pfx}-endtext {
      font-size: 5rem;
    }

    .${pfx}-cont,
    .${pfx}-block {
      display: inline-block;
    }
    .formwerk-logo:before {
      content:url('/images/FormWerk Logo DS.png'); /* with class ModalCarrot ??*/
      position:relative; /*or absolute*/
      z-index:100000; /*a number that's more than the modal box*/
      left:-50px;
      top:10px;
    }
  </style>` : ''

  if (c.blocks.indexOf(formwerkRef) >= 0) {
    bm.add(formwerkRef, {
      label: c.labelFormwerk,
      category: c.labelFormwerkCategory,
      attributes: { class: 'formwerk-logo' },
      content: `
        <div class="${pfx}" data-gjs-type="formwerk"></div>
        ${style}
      `,
    })
  }
}
