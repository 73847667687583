/* eslint-disable func-names */
import {
  qrCodeRef,
} from './consts'

export default function (editor, opt = {}) {
  const c = opt
  const bm = editor.BlockManager
  const pfx = c.qrCodeClsPfx
  const style = c.defaultStyle ? `<style>
    .${pfx} {
      text-align: center;
      font-family: Helvetica, serif;
    }

    .${pfx}-block {
      display: inline-block;
      margin: 0 10px;
      padding: 10px;
    }

    .${pfx}-digit {
      font-size: 5rem;
    }

    .${pfx}-endtext {
      font-size: 5rem;
    }

    .${pfx}-cont,
    .${pfx}-block {
      display: inline-block;
    }
  </style>` : ''

  if (c.blocks.indexOf(qrCodeRef) >= 0) {
    bm.add(qrCodeRef, {
      label: c.labelQrCode,
      category: c.labelQrCodeCategory,
      attributes: { class: 'fa fa-qrcode' },
      content: `
        <div class="${pfx}" data-gjs-type="qr-code"></div>
        ${style}
      `,
    })
  }
}
