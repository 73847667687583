/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
export default {
  init() {
    this.listenTo(this.model, 'change:options', this.rerender)
  },

  templateInput() {
    const { ppfx, clsField } = this

    return `<div class="${clsField}">
          <div data-input></div>
          <div class="${ppfx}sel-arrow">
            <div class="${ppfx}d-s-arrow"></div>
          </div>
        </div>`
  },
  getInputEl() {
    const { model, em } = this
    const propName = model.get('name')
    const opts = model.get('options') || []
    if (!this.$input) {
      const values = []
      const input = document.createElement('select')
      let options = ''
      const val = model.getTargetValue()
      const getOption = (el, _o) => {
        if (el.type === 'option') {
          let attrs = ''
          let name; let value; let
            style

          if (typeof el === 'string') {
            name = el
            value = el
          } else {
            name = el.name || el.label || el.value
            value = `${typeof el.value === 'undefined' ? el.id : el.value}`.replace(
              /"/g,
              '&quot;',
            )
            style = el.style ? el.style.replace(/"/g, '&quot;') : ''
            attrs += style ? ` style="${style}"` : ''
          }
          if (typeof val !== 'undefined' && val === value) {
            attrs += ' selected="selected" '
          }
          const resultName = em.t(`traitManager.traits.options.${propName}.${value}`) || name
          _o += `<option value="${value}"${attrs}>${resultName}</option>`
          values.push(value)
        } else {
          let o = `<optgroup label="${el.name}">`
          el.children.forEach(ch => {
            o = getOption(ch, o)
          })
          o += '</optgroup>'

          _o += o
        }

        return _o
      }
      opts.forEach(el => {
        options = getOption(el, options)
      })
      const valResult = values.indexOf(val) >= 0 ? val : model.get('default')
      if (!(typeof valResult === 'undefined')) {
        input.setAttribute('value', valResult)
      }
      input.innerHTML = options

      this.$input = input

      return input
    }

    return this.$input.get(0)

    // const input = document.createElement('select')
    // input.rows = 20
    // input.placeholder = `.class-name{
    //   color: #fff;
    //   font-size: 1em
    // }`

    // return input
  },
}
