/* eslint-disable default-case */
/* eslint-disable lines-between-class-members */
const yt = 'yt'
const vi = 'vi'
const ytnc = 'ytnc'

// const largeTrailAmount = 10
// const mediumTrailAmount = 5
// const smallTrailAmount = 3
// const firstPage = { d: 'M13,21,7,12l6-9H9L3,12l6,9Z  M21,21l-6-9,6-9H17l-6,9,6,9Z' }
// const lastPage = { d: 'M11,3l6,9-6,9h4l6-9L15,3Z  M3,3l6,9L3,21H7l6-9L7,3Z' }
// const nextPage = { d: 'M 8 3 L 14 12 L 8 21 L 12 21 L 18 12 L 12 3 L 8 3 z' }
// const previousPage = { d: 'M 13 3 L 7 12 L 13 21 L 17 21 L 11 12 L 17 3 L 13 3 z' }

export default class VideoComponent {
  component;
  Modal;
  error;
  timeout;
  preloader;
  dataContainer;
  container;
  aside;
  videoList;
  footer;
  pagination;
  videoDropZone;
  query;
  trailAmount;
  totalPages;
  totalVideos;
  current;
  currentIndex = 0;
  videoProvider;

  constructor(c) {
    this.component = c
  }
  get(prop) {
    return this.component.get(prop)
  }

  init() {
    const prevAttr = this.component.getAttrToHTML()
    this.component.getAttrToHTML = () => {
      const attr = JSON.parse(JSON.stringify(prevAttr))
      const prov = this.get('provider')
      switch (prov) {
        case yt:
        case ytnc:
        case vi:
          break
        default:
          if (this.get('loop')) attr.loop = 'loop'
          if (this.get('autoplay')) {
            attr.autoplay = 'autoplay'
            attr.muted = 1
          }
          if (this.get('controls')) attr.controls = 'controls'
      }
      attr.jumpToNextPage = this.get('jumpToNextPage')
      attr.src = this.getSrc()
      attr['data-video-provider'] = this.videoProvider
      attr['data-type'] = 'video'

      // attr.allow = 'autoplay; fullscreen'

      return attr
    }
    this.component.getYoutubeSrc = () => {
      const id = this.get('videoId')
      let url = this.get('ytUrl')
      const list = this.get('list')
      url += id + (id.indexOf('?') < 0 ? '?' : '')
      url += 'enablejsapi=1'
      url += list ? `&list=${list}` : ''
      url += this.get('autoplay') ? '&autoplay=1&mute=1' : ''
      url += !this.get('controls') ? '&controls=0&showinfo=0' : ''

      // Loop works only with playlist enabled
      // https://stackoverflow.com/questions/25779966/youtube-iframe-loop-doesnt-work
      url += this.get('loop') ? `&loop=1&playlist=${id}` : ''
      url += this.get('rel') ? '' : '&rel=0'
      url += this.get('modestbranding') ? '&modestbranding=1' : ''

      return url.replace('?&', '?')
    }

    /**
     * Returns url to youtube no cookie video
     * @return {string}
     * @private
     */
    this.component.getYoutubeNoCookieSrc = () => {
      let url = this.component.getYoutubeSrc()
      url = url.replace(this.get('ytUrl'), this.get('ytncUrl'))

      return url.replace('?&', '?')
    }

    /**
     * Returns url to vimeo video
     * @return {string}
     * @private
     */
    this.component.getVimeoSrc = () => {
      let url = this.get('viUrl')
      url += `${this.get('videoId')}?`

      // url += 'enablejsapi=1'
      url += this.get('autoplay') ? '&autoplay=1&muted=1' : ''
      url += this.get('loop') ? '&loop=1' : ''
      url += !this.get('controls') ? '&title=0&portrait=0&badge=0' : ''
      url += this.get('color') ? `&color=${this.get('color')}` : ''

      return url.replace('?&', '?')
    }

    this.component.updateSrc = this.updateSrc
    this.updateTraits()
    if (this.get('src')) {
      this.updateSrc()
    }
    this.component.listenTo(this.component, 'change:provider', this.updateTraits)
    this.component.listenTo(this.component, 'change:videoId change:provider', this.updateSrc)
    this.component.listenTo(this.component, 'change:jumpToNextPage', this.updateAttribute)
  }

  hideError() {
    this.error.style.height = '0px'
    this.error.style.opacity = 0
    this.timeout = setTimeout(() => {
      this.error.style.display = 'none'
    }, 2000)
  }

  showError() {
    clearTimeout(this.timeout)
    this.error.style.display = 'block'
    const h2 = this.error.querySelector('h2')

    this.error.style.height = `${h2.offsetHeight}px`
    this.error.style.opacity = 1
  }

  updateSourceTraitProvider = () => [
    this.getProviderTrait(),
    {
      type: 'button',
      text: 'MP4',
      full: true,
      command: 'open-videos',
    },
    {
      type: 'button',
      text: 'WebM',
      full: true,
      command: 'open-videos-webm',
    },
    {
      label: 'Quelle',
      name: 'src',
      placeholder: 'eg. ./media/video.mp4',
      changeProp: 1,
      attributes: { style: 'display: none' },
    },
    {
      label: 'Poster',
      name: 'poster',
      placeholder: 'eg. ./media/image.jpg',
      attributes: { style: 'display: none' },

      // changeProp: 1
    },
    this.getAutoplayTrait(),
    this.getLoopTrait(),
    this.getControlsTrait(),
  ]

  updateTraits = () => {
    const prov = this.get('provider')
    let tagName = 'iframe'
    this.videoProvider = 'source'
    let traits

    switch (prov) {
      case yt:
      case ytnc:
        traits = this.getYoutubeTraits()
        this.videoProvider = 'youtube'
        break
      case vi:
        this.videoProvider = 'vimeo'
        traits = this.getVimeoTraits()
        break
      default:
        tagName = 'video'
        traits = this.updateSourceTraitProvider()
    }
    traits.push({
      type: 'checkbox',
      label: 'Zur nächsten Seite springen, wenn das Video endet',
      name: 'jumpToNextPage',
      changeProp: 1,
    })
    this.component.view.el.setAttribute('data-video-provider', this.videoProvider)

    // this.component.view.el.setAttribute('allow', 'autoplay; fullscreen')
    this.component.set({ tagName }, { silent: 1 }) // avoid break in view
    this.component.set({ traits })
    this.component.em.trigger('component:toggled')
  }
  getSrc = () => {
    const prov = this.get('provider')
    let src = this.get('src')

    switch (prov) {
      case yt:
        src = this.component.getYoutubeSrc()
        break
      case ytnc:
        src = this.component.getYoutubeNoCookieSrc()
        break
      case vi:
        src = this.component.getVimeoSrc()
        break
    }

    return src.replace('?&', '?')
  }
  updateSrc = () => {
    const src = this.getSrc()
    this.component.set({ src })
  }
  updateAttribute = () => {
    const jumpToNextPage = this.get('jumpToNextPage')
    if (jumpToNextPage) {
      this.component.view.el.setAttribute('jumptonextpage', 'true')
    } else {
      this.component.view.el.removeAttribute('jumptonextpage')
    }
  }

  getProviderTrait = () => ({
    type: 'select',
    label: 'Anbieter',
    name: 'provider',
    changeProp: 1,
    options: [
      { value: 'so', name: 'HTML5 Quelle' },
      { value: yt, name: 'YouTube' },
      { value: ytnc, name: 'YouTube (No Cookie Option)' },
      { value: vi, name: 'Vimeo' },
    ],
  })

  getAutoplayTrait = () => ({
    type: 'checkbox',
    label: 'Autoplay',
    name: 'autoplay',
    changeProp: 1,
  })

  getLoopTrait = () => ({
    type: 'checkbox',
    label: 'Loop',
    name: 'loop',
    changeProp: 1,
  })

  getControlsTrait = () => ({
    type: 'checkbox',
    label: 'Steuerelemente',
    name: 'controls',
    changeProp: 1,
  })

  getYoutubeTraits = () => [
    this.getProviderTrait(),
    {
      label: 'Video ID',
      name: 'videoId',
      placeholder: 'eg. jNQXAC9IVRw',
      changeProp: 1,
    },
    this.getAutoplayTrait(),
    this.getLoopTrait(),
    this.getControlsTrait(),
    {
      type: 'checkbox',
      label: 'Ähnliche Videos',
      name: 'rel',
      changeProp: 1,
    },
    {
      type: 'checkbox',
      label: 'Reduziertes Design',
      name: 'modestbranding',
      changeProp: 1,
    },
  ]
  getVimeoTraits = () => [
    this.getProviderTrait(),
    {
      label: 'Video ID',
      name: 'videoId',
      placeholder: 'eg. 123456789',
      changeProp: 1,
    },
    {
      label: 'Farbe',
      name: 'color',
      placeholder: 'eg. FF0000',
      changeProp: 1,
    },
    this.getAutoplayTrait(),
    this.getLoopTrait(),
  ]
}
