<template>
  <div
    ref="configContainer"
    style="padding: 0px 5px 10px 5px"
  >
    <div class="gjs-lg-property gjs-sm-select gjs-sm-property__page_active">
      <div
        class="gjs-sm-label"
        data-sm-fields=""
      >
        <v-checkbox
          v-model="pageStatus"
          class="gjs-four-color no-pm"
          :label="`${$t('Page active')}?`"
        ></v-checkbox>
      </div>
    </div>
    <div class="gjs-lg-property gjs-sm-select gjs-sm-property__display">
      <div
        class="gjs-sm-label gjs-four-color"
        data-sm-label=""
      >
        <span
          class="gjs-sm-icon"
          title=""
        >
          {{ $t('Visibility duration') }}
        </span>
        <div
          v-if="visibility !== ''"
          class="gjs-sm-clear"
          @click="
            () => {
              changeConfig('', true)
            }
          "
        >
          <svg viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"
            ></path>
          </svg>
        </div>
      </div>
      <div
        class="gjs-fields"
        data-sm-fields=""
      >
        <div class="gjs-field gjs-select">
          <span id="gjs-sm-input-holder">
            <select
              v-model="visibility"
              :label="$t('Select')"
              single-line
              @change="changeConfig(visibility)"
            >
              <option
                v-for="item in items"
                :key="item.value"
                :value="item.value"
              >
                {{ item.label }}
              </option>
            </select>
          </span>
          <div class="gjs-sel-arrow">
            <div class="gjs-d-s-arrow"></div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="visibility === 'relativ'">
      <div class="flex">
        <div class="flex-end">
          <p class="evo-config-description">
            {{ $t('configRelativDescription') }}
          </p>
        </div>
        <div
          class="gjs-lg-properties"
          style="padding: 0 10px 10px"
        >
          <div class="gjs-lg-property gjs-lg-select gjs-sm-property__display">
            <div
              class="gjs-sm-label gjs-four-color"
              data-sm-label=""
            >
              <span
                class="gjs-sm-icon"
                title=""
              >
                {{ $t('Duration (seconds)') }}
              </span>
            </div>
            <div
              class="gjs-fields"
              data-sm-fields=""
            >
              <div class="gjs-field gjs-select">
                <span id="gjs-sm-input-holder">
                  <input
                    v-model="relativ"
                    type="number"
                    :placeholder="$t('Duration (seconds)')"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="visibility === 'absolut'">
      <div class="flex">
        <div class="flex-end">
          <p class="evo-config-description">
            {{ $t('configAbsolutDescription') }}
          </p>
          <button
            type="button"
            class="gjs-pn-btn fa fa-plus-square"
            @click="addInput"
          >
            {{ $t('Add') }}
          </button>
        </div>
        <div>
          <input-group
            v-for="(name, i) in absolut"
            :key="i"
            :value="name"
            :index="i"
            :week-days="weekDays"
            :remove-row="removeInput"
            @input="name => handleInput(name, i)"
          />
        </div>
      </div>
    </div>
    <div
      class="gjs-lg-properties"
      style="padding: 0 10px 10px"
    >
      <div class="gjs-lg-property gjs-lg-select gjs-sm-property__display">
        <div
          class="gjs-sm-label gjs-four-color"
          data-sm-label=""
        >
          <span
            class="gjs-sm-icon"
            title=""
          >
            {{ $t('CSS') }}
          </span>
        </div>
        <div
          class="gjs-fields"
          data-sm-fields=""
        >
          <div class="gjs-field gjs-select">
            <span id="gjs-sm-input-holder">
              <textarea
                v-model="page_css"
                rows="20"
                placeholder=".class-name{
          color: #fff;
          font-size: 1em
        }"
              ></textarea>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-restricted-syntax */
import inputGroup from './AbsolutInput.vue'

export default {
  name: 'EvoConfig',
  components: {
    'input-group': inputGroup,
  },
  props: {
    settings: {
      type: Object,
      required: true,
    },
    setSettings: {
      type: Function,
      required: true,
    },
    weekDays: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      visibility: this.settings.visibility,
      relativ: this.settings.relativ,
      pageStatus: this.settings.status === 'active',
      page_css: this.settings.page_css,
      absolut: Array.isArray(this.settings.absolut)
        ? JSON.parse(JSON.stringify(this.settings.absolut))
        : [
          {
            startTime: '',
            endTime: '',
            startDate: '',
            endDate: '',
            weekDay: [],
          },
        ],
      items: [
        { label: ' ', value: '' },
        { label: this.$i18n.t('Relativ'), value: 'relativ' },
        { label: this.$i18n.t('Absolut'), value: 'absolut' },
      ],
    }
  },
  watch: {
    relativ: {
      handler(val) {
        this.setSettings(val, 'relativ')
      },
      deep: true,
      immediate: true,
    },
    page_css: {
      handler(val) {
        this.setSettings(val, 'page_css')
      },
      deep: true,
      immediate: true,
    },
    pageStatus: {
      handler(val) {
        this.setSettings(val ? 'active' : 'disabled', 'status')
      },
      deep: true,
      immediate: true,
    },
    settings: {
      handler(val) {
        // eslint-disable-next-line no-restricted-syntax
        // eslint-disable-next-line guard-for-in
        for (const index in val) {
          this.changeConfig(val[index], true, index)
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    changeConfig(v, reset = false, field = 'visibility') {
      if (field === 'status') {
        this.pageStatus = v === 'active'
      }
      this.setSettings(v, field)
      if (reset) {
        this[field] = v
      }
      localStorage.setItem('settings', JSON.stringify(this.settings))
    },
    addInput() {
      this.absolut = this.absolut.concat({
        startTime: '',
        endTime: '',
        startDate: '',
        endDate: '',
        weekDay: '',
      })
      this.setSettings(this.absolut, 'absolut')
    },
    removeInput(ind) {
      this.absolut.splice(ind, 1)
      this.setSettings(this.absolut, 'absolut')
    },
    handleInput(name, idx) {
      this.absolut = this.absolut.map((n, i) => (i === idx ? name : n))
      this.setSettings(this.absolut, 'absolut')
    },
  },
}
</script>
<style lang="scss">
.evo-config-description {
  margin: 10px;
  text-align: left;
}
.v-application .gjs-lg-property {
  .primary--text,
  .v-label {
    color: #d278c9 !important;
    caret-color: #d278c9 !important;
    font-size: 12px !important;
  }
  .primary--text {
    .v-icon.v-icon::after {
      background-color: #d278c9 !important;
      color: #d278c9 !important;
      caret-color: #d278c9 !important;
    }
  }
  .v-icon__svg,
  .v-input--selection-controls__ripple {
    height: 16px;
    width: 16px;
  }
  .no-pm {
    padding: 0 !important;
    margin: 0 !important;
    .v-messages {
      display: none;
    }
  }
}
</style>
