/* eslint-disable no-restricted-syntax */
import grapesjs from 'grapesjs'
import loadComponents from './components'
import loadBlocks from './blocks'
import {
  bueTermineRef,
} from './consts'

export default grapesjs.plugins.add('gjs-component-bue-termine', (editor, opts = {}) => {
  const c = opts

  const defaults = {
    blocks: [bueTermineRef],

    // Default style
    defaultStyle: true,

    // Text to show when the bueTermine is ended
    color: '#00000',

    textType: 'text',
    numberType: 'number',
    apiHeaders: {},
    emptyText: '',

    // Text input type, eg, 'text', 'url???'
    colorInputType: 'color',

    // BueTermine class prefix
    bueTermineClsPfx: 'bueTermine',

    // BueTermine label
    labelBueTermine: 'BuE Termine',

    // Qrcode category label
    labelBueTermineCategory: 'Extra',
  }

  // Load defaults
  for (const name in defaults) {
    if (!(name in c)) { c[name] = defaults[name] }
  }

  // Add components
  loadComponents(editor, c)

  // Add components
  loadBlocks(editor, c)
})
