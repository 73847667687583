module.exports = `
#bue-termine-modal > .gjs-mdl-dialog {
    height: 85vh;
    width: 75vw;
    max-width: 100%;
    display: flex;
    flex-flow: column nowrap;
}

#bue-termine-modal > .gjs-mdl-dialog > .gjs-mdl-content {
    flex-grow: 1;
    height: 0;
    overflow: hidden;
}

#bue-termine-modal > .gjs-mdl-dialog > .gjs-mdl-content > div:first-child { height: 100% }
#bue-termine-modal > .gjs-mdl-dialog > .gjs-mdl-content > div > #data-container {
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
}

#bue-termine-modal > .gjs-mdl-dialog > .gjs-mdl-content > div > #data-container > #termine-container {
    flex-grow: 1;
    height: 0;
    overflow-y: auto;
    display: flex;
}

#bue-termine-modal > .gjs-mdl-dialog > .gjs-mdl-content > div > #data-container > #termine-container .termine-error {
    text-align: center;
    width: 100%;
    border-bottom: 1px solid rgba(0,0,0,.2);
    border-top: 1px solid rgba(0,0,0,.2);
    background: #444;
    margin: 0;
    box-sizing: border-box;
    cursor: pointer;
    overflow: hidden;
    left: 0;
    right: 0;
    transition: height .3s ease-in-out, opacity .3s ease-in-out;
}

#bue-termine-modal > .gjs-mdl-dialog > .gjs-mdl-content > div > #data-container > #termine-container .termine-error > h2 {
    padding: 0.25em;
    margin: 0;
}

#bue-termine-modal > .gjs-mdl-dialog > .gjs-mdl-content > div > #data-container > #termine-container .termine-error:hover > h2 {
    color: #d278c9;
}

#bue-termine-modal > .gjs-mdl-dialog > .gjs-mdl-content > div > #data-container > aside#termine-tabs .tablinks{
    font-size: .9em;
    border-top: 1px solid #ddd;
    border-right: 1px solid #ddd;
    opacity: .5;
    transform: skew(20deg, 0deg);
}

#bue-termine-modal > .gjs-mdl-dialog > .gjs-mdl-content > div > #data-container > aside#termine-tabs > .tablinks > span{
    display: inline-block;
    padding: 0 .7em;
    transform: skew(-20deg, 0deg);
}

#bue-termine-modal > .gjs-mdl-dialog > .gjs-mdl-content > div > #data-container > aside#termine-tabs > .tablinks.gjs-four-color{
    border-top: 1px solid #ddd;
    border-right: 1px solid #ddd;
    opacity: 1;
}

#bue-termine-modal > .gjs-mdl-dialog > .gjs-mdl-content > div > #data-container > #termine-container ul#termine-list {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 0.25rem;
    flex-grow: 1;
    overflow-y: auto
}

#bue-termine-modal > .gjs-mdl-dialog > .gjs-mdl-content > div > #data-container > #termine-container ul#termine-list,
#bue-termine-modal > .gjs-mdl-dialog > .gjs-mdl-content > div > #data-container > footer#termine-paginate-footer > nav#termine-paginate > ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

#bue-termine-modal > .gjs-mdl-dialog > .gjs-mdl-content > div > #data-container > #termine-container ul#termine-list li.termine-container{
    width: 100%;
    box-sizing:border-box;
}

#bue-termine-modal > .gjs-mdl-dialog > .gjs-mdl-content > div > #data-container > #termine-container ul#termine-list li.termine-container > figure{
    margin: .5rem;
    cursor: pointer;
}

#bue-termine-modal > .gjs-mdl-dialog > .gjs-mdl-content > div > #data-container > #termine-container ul#termine-list li.termine-container > figure > figcaption{
    text-align: center;
    margin: .2em;
    font-size: .9em;
}

#bue-termine-modal > .gjs-mdl-dialog > .gjs-mdl-content > div > #data-container > #termine-container ul#termine-list li.termine-container > figure > img{
    transition: transform .2s ease-in-out, box-shadow .2s ease-in-out;
    font-size: 2vh;
}

#bue-termine-modal > .gjs-mdl-dialog > .gjs-mdl-content > div > #data-container > #termine-container ul#termine-list li.termine-container > figure:hover > img{
    transform: translate(0, -1em) scale(1.1);
    box-shadow: 0 2.2em 1.9em -1.8em rgb(145 145 145);
    -webkit-box-shadow: 0 2.2em 1.9em -1.8em rgb(145 145 145);
    -moz-box-shadow: 0 2.2em 1.9em -1.8em rgb(145 145 145);
}

@media screen and (max-width: 61.875em){
    #bue-termine-modal > .gjs-mdl-dialog > .gjs-mdl-content > div > #data-container > #termine-container ul#termine-list li.termine-container{
        width: 50%;
    }
}

@media screen and (max-width: 37.5em){
    #bue-termine-modal > .gjs-mdl-dialog > .gjs-mdl-content > div > #data-container > #termine-container ul#termine-list li.termine-container{
        width: 100%;
    }
}

#bue-termine-modal > .gjs-mdl-dialog > .gjs-mdl-content > div > #data-container > footer#termine-paginate-footer > nav#termine-paginate > ul > li {
    padding: 0 .5em;
}

/* Pagination styling */

#bue-termine-modal > .gjs-mdl-dialog > .gjs-mdl-content > div > #data-container > footer#termine-paginate-footer > nav#termine-paginate > ul > li > a,
#bue-termine-modal > .gjs-mdl-dialog > .gjs-mdl-content > div > #data-container > footer#termine-paginate-footer > nav#termine-paginate > ul > li > a > svg {
    pointer-events: none
}

#bue-termine-modal > .gjs-mdl-dialog > .gjs-mdl-content > div > #data-container > footer#termine-paginate-footer{
    width: 100%;
    z-index: 5;
    border-top: 1px solid rgba(0,0,0,.2);
}

#bue-termine-modal > .gjs-mdl-dialog > .gjs-mdl-content > div > #data-container > footer#termine-paginate-footer > nav#termine-paginate{
    width: 100%;
}

#bue-termine-modal > .gjs-mdl-dialog > .gjs-mdl-content > div > #data-container > footer#termine-paginate-footer > nav#termine-paginate > ul[role=menubar]{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    padding: 1em;
}

#bue-termine-modal > .gjs-mdl-dialog > .gjs-mdl-content > div > #data-container > footer#termine-paginate-footer > nav#termine-paginate > ul[role=menubar] li{
    list-style: none;
}

#bue-termine-modal > .gjs-mdl-dialog > .gjs-mdl-content > div > #data-container > footer#termine-paginate-footer > nav#termine-paginate > ul[role=menubar] li:not(.dots){
    cursor: pointer;
}

#bue-termine-modal > .gjs-mdl-dialog > .gjs-mdl-content > div > #data-container > footer#termine-paginate-footer > nav#termine-paginate > ul[role=menubar] li > a > svg{
    width: 1.4em;
    fill: #ddd;
}

#bue-termine-modal > .gjs-mdl-dialog > .gjs-mdl-content > div > #data-container > footer#termine-paginate-footer > nav#termine-paginate > ul[role=menubar] li > .disabled-link > svg{
    fill: #505050;
}

#bue-termine-modal > .gjs-mdl-dialog > .gjs-mdl-content > div > #data-container > footer#termine-paginate-footer > nav#termine-paginate > ul[role=menubar] li > .disabled-link{
    color: #505050;
}

#bue-termine-modal > .gjs-mdl-dialog > .gjs-mdl-content > div > #data-container > footer#termine-paginate-footer > nav#termine-paginate > ul[role=menubar] li[aria-label^=Page],
#bue-termine-modal > .gjs-mdl-dialog > .gjs-mdl-content > div > #data-container > footer#termine-paginate-footer > nav#termine-paginate > ul[role=menubar] li[aria-hidden=true]
{
    margin: 0 .3rem;
    border-radius: 50em;
}

#bue-termine-modal > .gjs-mdl-dialog > .gjs-mdl-content > div > #data-container > footer#termine-paginate-footer > nav#termine-paginate > ul[role=menubar] li[aria-label^=Page] > a,
#bue-termine-modal > .gjs-mdl-dialog > .gjs-mdl-content > div > #data-container > footer#termine-paginate-footer > nav#termine-paginate > ul[role=menubar] li[aria-hidden=true] > a
{
    text-decoration: none;
}

#bue-termine-modal > .gjs-mdl-dialog > .gjs-mdl-content > div > #data-container > footer#termine-paginate-footer > nav#termine-paginate > ul[role=menubar] li[aria-label^=First],
#bue-termine-modal > .gjs-mdl-dialog > .gjs-mdl-content > div > #data-container > footer#termine-paginate-footer > nav#termine-paginate > ul[role=menubar] li[aria-label^=Previous],
#bue-termine-modal > .gjs-mdl-dialog > .gjs-mdl-content > div > #data-container > footer#termine-paginate-footer > nav#termine-paginate > ul[role=menubar] li[aria-label^=Next],
#bue-termine-modal > .gjs-mdl-dialog > .gjs-mdl-content > div > #data-container > footer#termine-paginate-footer > nav#termine-paginate > ul[role=menubar] li[aria-label^=Last]
{
    padding: 0 .2rem;
}
.h-100 {height: 100%}
.w-10 {width: 10%;}
.w-20 {width: 20%;}
.w-30 {width: 30%;}
.w-40 {width: 40%;}
.w-50 {width: 50%;}
.w-60 {width: 60%;}
.w-70 {width: 70%;}
.w-80 {width: 80%;}
.w-90 {width: 90%;}
.w-100 {width: 100%;}
.h-100 {height: 100%}

#termine-container .preview {
    border-right: 1px solid rgba(0,0,0,.2);
}
.slist {
  list-style: none;
  padding: 0;
  margin: 0;
}
.slist li {
  margin: 10px;
  padding: 15px;
  border: 1px solid rgba(0,0,0,.2);
  background: rgba(0,0,0,.2);
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* (B) DRAG-AND-DROP HINT */
.slist li.hint {
  border: 1px solid #9ac1ff;
  background: #b4d5ff;
}
.slist li.active {
  border: 1px solid #a5f4ff;
  background: #e7f8ff;
}
.flex-end{
    justify-content: end;
    display: flex;
}
#preview-bue-termine-modal {
    overflow: auto
}
#preview-bue-termine {
    overflow: auto
}
#preview-bue-termine table, 
#preview-bue-termine th,
#preview-bue-termine td,
#preview-bue-termine-modal table, 
#preview-bue-termine-modal th,
#preview-bue-termine-modal td  {
  border: 1px solid black;
  border-collapse: collapse;
}
#preview-bue-termine table,
#preview-bue-termine-modal table {
   height:calc(100% - 40px)
}
.pagination-wrapper {
  background: #222222;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 5px 0px;
}
.pagination span{
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
.pagination span.active{
  border: 1px solid #3498db;
}
.pagination span:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  background: #e74c3c;
  border-radius: 50%;
}
.pagination {
    display: flex;
    justify-content: center;
}
#preview-bue-termine table {
    display: block;
    overflow-x: auto;
}
#preview-bue-termine table,
#preview-bue-termine tbody,
#preview-bue-termine thead
{
    max-width: 100%;
    width: 100%;
}
.card {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  width: 40%;
  border-radius: 5px;
  margin: 0 5px 10px;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.container {
  padding: 2px 16px;
}
.card-container{
    display: flex;
}
.bue-termine-rows-listview{
    display:flex;
    flex-wrap: wrap;
}
.bue-termine-rows-listview .row {
    width: 100%;
    display:flex;
    border-bottom: 2px solid rgba(0,0,0,0.2);
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}
.bue-termine-rows-listview .row:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.bue-termine-rows-listview .column {
  float: left;
  width: 100%;
  padding: 10px;
}

/* Clear floats after the columns */
.bue-termine-rows-listview.row:after {
  content: "";
  display: table;
  clear: both;
}
 .bue-termine-rows-listview * {
    box-sizing: border-box;
 }
`
