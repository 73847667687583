/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
export default {
  events: {
    keyup: 'onChange',
  },
  getInputEl() {
    const { model } = this
    if (!this.$input) {
      const input = document.createElement('textarea')
      input.rows = 20
      input.placeholder = `.class-name{
          color: #fff;
          font-size: 1em
        }`
      const val = model.getTargetValue()
      input.value = val
      this.$input = input

      return input
    }

    return this.$input.get(0)
  },
}
