/* eslint-disable import/prefer-default-export */
export const bueTermineRef = 'bueTermine'

export const bueTermineContentConfig = [
  {
    id: 0,
    label: 'DozentName',
    sort: 0,
    dataId: 'DozentName',
    element: 'p',
    visible: true,
    filter: '',
  },
  {
    id: 1,
    label: 'DozentPersonID',
    sort: 1,
    dataId: 'DozentPersonID',
    element: 'p',
    visible: true,
    filter: '',
  },
  {
    id: 2,
    label: 'DozentRessourceID',
    sort: 2,
    dataId: 'DozentRessourceID',
    element: 'p',
    visible: true,
    filter: '',
  },
  {
    id: 3,
    label: 'Datum',
    sort: 3,
    dataId: 'Datum',
    element: 'p',
    visible: true,
    filter: '',
  },
  {
    id: 4,
    label: 'Uhrzeit',
    sort: 4,
    dataId: 'Uhrzeit',
    element: 'p',
    visible: true,
    filter: '',
  },
  {
    id: 5,
    label: 'UStd',
    sort: 5,
    dataId: 'UStd',
    element: 'p',
    visible: true,
    filter: '',
  },
  {
    id: 6,
    label: 'Grp',
    sort: 6,
    dataId: 'Grp',
    element: 'p',
    visible: true,
    filter: '',
  },
  {
    id: 7,
    label: 'Kurs',
    sort: 7,
    dataId: 'Kurs',
    element: 'p',
    visible: true,
    filter: '',
  },
  {
    id: 8,
    label: 'UStd',
    sort: 8,
    dataId: 'UStd',
    element: 'p',
    visible: true,
    filter: '',
  },
  {
    id: 9,
    label: 'Thema',
    sort: 9,
    dataId: 'Thema',
    element: 'p',
    visible: true,
    filter: '',
  },
  {
    id: 10,
    label: 'Kommentar',
    sort: 10,
    dataId: 'Kommentar',
    element: 'p',
    visible: true,
    filter: '',
  },
  {
    id: 11,
    label: 'Nummer',
    sort: 11,
    dataId: 'Nummer',
    element: 'p',
    visible: true,
    filter: '',
  },
  {
    id: 12,
    label: 'Status',
    sort: 12,
    dataId: 'Status',
    element: 'p',
    visible: true,
    filter: '',
  },
  {
    id: 13,
    label: 'Ressource',
    sort: 13,
    dataId: 'Ressource',
    element: 'p',
    visible: true,
    filter: '',
  },
  {
    id: 14,
    label: 'TRID',
    sort: 14,
    dataId: 'TRID',
    element: 'p',
    visible: true,
    filter: '',
  },
  {
    id: 15,
    label: 'EreignisID',
    sort: 15,
    dataId: 'EreignisID',
    element: 'p',
    visible: true,
    filter: '',
  },
]
