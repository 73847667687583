<template>
  <div style="height: 100vh">
    <div class="loader"></div>
    <div class="snackbar">
      {{ $t('Saved') }}
    </div>
    <div
      id="gjs"
      style="height: 100vh"
    ></div>
    <div style="display: none">
      <!-- Components -->
      <evo-config
        ref="config"
        :settings="settings"
        :set-settings="setSettings"
        :week-days="weekDays"
      />
    </div>
  </div>
</template>

<script>
/* eslint-disable no-alert */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
import axios from '@axios'
import html2canvas from 'html2canvas'

// langs
import de from './grapesjs/locales/de'

import ConfigBlock from './grapesjs/buttons/ConfigBlock'
import EvoConfig from './grapesjs/components/Config.vue'
import 'dropzone/dist/dropzone.css'
import { bueTermineContentConfig } from './grapesjs/blocks/bue-termine/consts'

/**
 * Traits
 */
import SelectGroupTrait from './grapesjs/traits/SelectGroup'
import CssTextareaTrait from './grapesjs/traits/CssTextarea'

const grapesjs = require('grapesjs') // can be replaced with the es6 import if a transpiler like babel is used
require('grapesjs/dist/css/grapes.min.css')
require('grapesjs-mjml/dist')
require('grapesjs-preset-webpage')
require('./grapesjs/blocks/qr-code')
require('./grapesjs/blocks/formwerk')
require('./grapesjs/blocks/bue-termine')
const videoBlock = require('./grapesjs/blocks/video')

export default {
  name: 'GrapeJsIntegration',
  components: {
    EvoConfig,
  },
  data() {
    const weekDays = [
      {
        value: 0,
        label: this.$i18n.t('Monday'),
      },
      {
        value: 1,
        label: this.$i18n.t('Tuesday'),
      },
      {
        value: 2,
        label: this.$i18n.t('Wednesday'),
      },
      {
        value: 3,
        label: this.$i18n.t('Thursday'),
      },
      {
        value: 4,
        label: this.$i18n.t('Friday'),
      },
      {
        value: 5,
        label: this.$i18n.t('Saturday'),
      },
      {
        value: 6,
        label: this.$i18n.t('Sunday'),
      },
    ]

    return {
      settings: {
        status: '',
        visibility: '',
        relativ: 0,
        page_css: '',
        absolut: [
          {
            startTime: '',
            endTime: '',
            startDate: '',
            endDate: '',
            weekDay: [],
          },
        ],
      },
      weekDays,
    }
  },
  async mounted() {
    const appRes = await axios.get('/appsettings')
    const appSettings = (appRes.data.data || {}).reduce((v, a) => {
      // eslint-disable-next-line no-param-reassign
      v[a.key] = a

      return v
    }, {})
    let formwerkForms = []
    const isConfiguredFormwerk = !!(appSettings.formwerk_api_key && appSettings.formwerk_api_url)
    const extraPlugins = [
      'gjs-preset-webpage',
      'gjs-component-qr-code',
      'gjs-component-bue-termine',
      'grapesjs-mjml',
      videoBlock.default,
    ]
    if (isConfiguredFormwerk) {
      extraPlugins.push('gjs-component-formwerk')
      const fRes = await fetch(`${appSettings.formwerk_api_url.value}api/forms-with-folders`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json;odata.metadata=full',
          'X-Formwerk-Api-Token': appSettings.formwerk_api_key.value, // '$2y$10$vEjNUk2Iy63QFWmwuC9Iv.zXHM7GJGQ7DVGmvUMLDcVDS0SqbUoLW',
          'X-Formwerk-Api-Domain': window.location.hostname,
        },
      })
      const result = await fRes.json()
      if (result && Array.isArray(result) && result.length) {
        formwerkForms = result
      }
    }

    const accessToken = localStorage.getItem('accessToken')

    // grapesjs equals the grapesjs from the first require line
    const editor = grapesjs.init({
      container: '#gjs',
      showOffsets: true,
      fromElement: true,
      noticeOnUnload: false,
      canvas: {
        scripts: ['/lib/qr-code-styling.js', 'https://cdn.tiny.cloud/1/no-api-key/tinymce/6/tinymce.min.js'],
      },
      styleManager: { sectors: [] },
      plugins: extraPlugins,
      formwerkForms,
      pluginsOpts: {
        [videoBlock.default]: {
          preloader: '/images/loading-icon-gif-6.gif',
          resources: ['local'],
          localLoadUrl: `${process.env.VUE_APP_BASE_URL}/grapejs/resources/video`,
          localHeaders: {
            Authorization: `Bearer ${accessToken}`,
          },
          per_page: 10,
        },
        'gjs-preset-webpage': {},
        'gjs-component-bue-termine': {
          layout: '',
          relativVisibility: true,
          activatePagination: true,
          perPage: 10,
          pageInterval: 10,
          dateRange: 14,
          showBullets: true,
          contentConfig: bueTermineContentConfig,
          apiUrl: `${process.env.VUE_APP_BASE_URL}/bue_xml_events`,
          emptyText: '<p></p>',
          bueTermineCss: '',
          disablePage: true,
        },
      },
      i18n: {
        locale: 'de',
        localeFallback: 'de',
        detectLocale: false,

        // debug: true,
        messages: { de },
      },
      assetManager: {
        uploadFile(e) {
          const files = e.dataTransfer ? e.dataTransfer.files : e.target.files
          if (files.length) {
            const bigFiles = []
            for (const file of files) {
              if (file.size > 1000000) {
                bigFiles.push(file)
                break
              }
            }
            if (bigFiles.length) {
              alert('Bild zu groß. Die maximale Bildgröße beträgt 1 MBBild zu groß. Die maximale Bildgröße beträgt 1 MB')
            } else {
              // this.onUploadError(
              //   new Error('Testing'),
              // )

              this.embedAsBase64 = this.constructor.embedAsBase64.bind(this)
              this.embedAsBase64(e)
            }
          }
        },
      },

      // storageManager: { type: 'local' },
    })

    // Each new type extends the default Trait
    editor.TraitManager.addType('css-textarea', CssTextareaTrait)
    editor.TraitManager.addType('select-with-group', SelectGroupTrait)
    const currentPageId = this.$route.params.id
    axios.get(`pages/${currentPageId}`).then(response => {
      const { data } = response.data
      let components = []
      try {
        components = JSON.parse(data.components)
      } catch (e) {
        // no error here
      }
      editor.setComponents(components)
      editor.setStyle(data.css)
      this.settings.status = data.status ?? ''
      this.settings.visibility = data.visibility ?? ''
      this.settings.page_css = data.page_css ?? ''
      this.settings.relativ = data.relativ ?? 0
      if (data.absolut_config && Array.isArray(data.absolut_config)) {
        this.settings.absolut = data.absolut_config
      } else {
        this.settings.absolut = []
      }

      // this.settings.absolut[0].startTime = data.start_time ?? ''
      // this.settings.absolut[0].endTime = data.end_time ?? ''
      // this.settings.absolut[0].startDate = data.start_date ?? ''
      // this.settings.absolut[0].endDate = data.end_date ?? ''
      // this.settings.absolut[0].weekDay = JSON.parse(data.week_days) ?? []

      localStorage.setItem('settings', JSON.stringify(this.settings))
    })

    window.GRAPEJS_EDITOR = editor
    const locales = {
      config: this.$i18n.t('Config'),
    }
    editor.onReady(() => {
      if (
        editor.Blocks
        && editor.Blocks.getCategories()._byId
        && editor.Blocks.getCategories()._byId.Forms
        && editor.Blocks.getCategories()._byId.Forms.view
        && editor.Blocks.getCategories()._byId.Forms.view.el
      ) {
        editor.Blocks.getCategories()._byId.Forms.view.el.remove()
      }

      const addComponentStyle = (configs, sm, vw, vh, h) => {
        configs.forEach(c => {
          sm.select(c)
          sm.addStyleTargets({ width: vw, height: `${h}${vh}`, float: 'left' })
          if (
            c.attributes
            && c.attributes.components
            && Array.isArray(c.attributes.components.models)
            && c.attributes.components.models.length
          ) {
            const { models } = c.attributes.components
            const size = 100 / models.length
            if (c.attributes.name === 'Row') {
              addComponentStyle(models, sm, `${size}%`, '%', 100)
            } else {
              addComponentStyle(models, sm, '100%', '%', size)
            }
          }
        })
      }

      const updateStyle = () => {
        const compo = editor.getComponents()
        const sm = editor.StyleManager
        const models = compo.models || []
        if (models.length) {
          const h = 100 / models.length
          addComponentStyle(compo.models || [], sm, '100vw', 'vh', h)
        }
      }
      editor.on('component:add', updateStyle)
      editor.on('component:remove', updateStyle)

      // add pannel
      const pn = editor.Panels
      editor.Commands.add('evo:config', ConfigBlock.command(this.$refs.config.$refs.configContainer, locales))
      pn.addButton('views', {
        id: 'evo:config',
        attributes: { class: 'fa fa-wrench', title: 'Konfiguration' },
        active: false,
        command: 'evo:config',
      })
      pn.addButton('options', [
        {
          id: 'save',
          className: 'fa fa-floppy-o icon-blank',
          label: ' Speichern',
          command(page) {
            document.querySelector('.loader').style = 'display:initial'
            const settings = JSON.parse(localStorage.getItem('settings'))
            html2canvas(page.getWrapper().view.el).then(canvas => {
              canvas.toBlob(blob => {
                axios
                  .put(`/pages/${currentPageId}`, {
                    html: page.getHtml(),
                    components: JSON.stringify(page.getComponents()),
                    css: page.getCss(),
                    status: settings.status,
                    visibility: settings.visibility,
                    page_css: settings.page_css,
                    relativ: settings.relativ,
                    absolut_config: JSON.stringify(settings.absolut),
                    screenshot: `/storage/screenshot/${currentPageId}.png`,

                    // start_time: settings.absolut[0].startTime,
                    // end_time: settings.absolut[0].endTime,
                    // start_date: settings.absolut[0].startDate,
                    // end_date: settings.absolut[0].endDate,
                    // week_days: JSON.stringify(settings.absolut[0].weekDay),
                  })
                  .then(() => {
                    if (blob) {
                      const fd = new FormData()
                      fd.append('screenshot', blob, `${currentPageId}.png`)
                      axios
                        .post('/pages/screenshot', fd, {
                          headers: {
                            'content-type': 'multipart/form-data',
                          },
                        })
                        .then(() => {
                          document.querySelector('.loader').style = 'display:none'
                          document.querySelector('.snackbar').style = 'display:initial'
                          setTimeout(() => {
                            document.querySelector('.snackbar').style = 'display:none'
                          }, 1000)
                        })
                    } else {
                      document.querySelector('.loader').style = 'display:none'
                      document.querySelector('.snackbar').style = 'display:initial'
                      setTimeout(() => {
                        document.querySelector('.snackbar').style = 'display:none'
                      }, 1000)
                    }
                  })
              })
            })
          },
          attributes: { title: 'Vorlage speichern' },
        },
      ])
    })
  },
  methods: {
    setSettings(value, field) {
      this.settings[field] = value
    },
  },
}
</script>

<style scoped src="grapesjs/dist/css/grapes.min.css">
/* global styles */
</style>

<style scoped lang="scss">
#gjs {
  height: 100vh !important;
}
.loader {
  border: 16px solid #444444;
  border-radius: 50%;
  border-top: 16px solid #d278c9;
  width: 120px;
  height: 120px;
  -webkit-animation: spin-data-v-24b0f47a 2s linear infinite;
  animation: spin-data-v-24b0f47a 2s linear infinite;
  position: absolute;
  z-index: 10;
  position: fixed;
  top: 50%;
  left: 35%;
  display: none;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.snackbar {
  display: none;
  min-width: 250px;
  margin-left: -125px;
  color: #fff;
  background-color: #d278c9;
  text-align: center;
  border-radius: 7px;
  padding: 16px;
  position: fixed;
  z-index: 10;
  left: 39%;
  bottom: 30px;
}
</style>
