/* eslint-disable no-restricted-syntax */
import grapesjs from 'grapesjs'
import loadComponents from './components'
import loadBlocks from './blocks'
import {
  formwerkRef,
} from './consts'

export default grapesjs.plugins.add('gjs-component-formwerk', (editor, opts = {}) => {
  const c = opts

  const defaults = {
    blocks: [formwerkRef],

    // Default style
    defaultStyle: true,

    formId: '',
    formUrl: '',

    // Text to show when the formwerk is ended
    color: '#00000',

    textType: 'text',
    numberType: 'number',

    // Text input type, eg, 'text', 'url???'
    colorInputType: 'color',

    // Formwerk class prefix
    formwerkClsPfx: 'formwerk',

    // Formwerk label
    labelFormwerk: 'Formwerk',

    // Qrcode category label
    labelFormwerkCategory: 'Extra',
  }

  // Load defaults
  for (const name in defaults) {
    if (!(name in c)) { c[name] = defaults[name] }
  }

  // Add components
  loadComponents(editor, c)

  // Add components
  loadBlocks(editor, c)
})
