/* eslint-disable no-restricted-syntax */
import grapesjs from 'grapesjs'
import loadComponents from './components'
import loadBlocks from './blocks'
import {
  qrCodeRef,
} from './consts'

export default grapesjs.plugins.add('gjs-component-qr-code', (editor, opts = {}) => {
  const c = opts

  const defaults = {
    blocks: [qrCodeRef],

    // Default style
    defaultStyle: true,

    // Default start time, eg. '2018-01-25 00:00'
    url: '',

    // Text to show when the qr-code is ended
    color: '#00000',

    textType: 'text',
    numberType: 'number',

    // Text input type, eg, 'text', 'url???'
    colorInputType: 'color',

    // QrCode class prefix
    qrCodeClsPfx: 'qr-code',

    // QrCode label
    labelQrCode: 'QR Code',

    // Qrcode category label
    labelQrCodeCategory: 'Extra',
  }

  // Load defaults
  for (const name in defaults) {
    if (!(name in c)) { c[name] = defaults[name] }
  }

  // Add components
  loadComponents(editor, c)

  // Add components
  loadBlocks(editor, c)
})
