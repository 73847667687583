/* eslint-disable no-undef */
/* eslint-disable func-names */

/* eslint-disable consistent-return */
export default function (editor, opt = {}) {
  const c = opt
  const domc = editor.DomComponents
  const defaultType = domc.getType('default')
  const defaultModel = defaultType.model
  const defaultView = defaultType.view
  const QR_CODE_TYPE = 'qr-code'

  domc.addType(QR_CODE_TYPE, {

    model: defaultModel.extend({
      defaults: {
        ...defaultModel.prototype.defaults,
        qrCodeUrl: c.url,
        qrCodeColor: c.qrCodeColor,
        droppable: false,
        traits: [{
          label: 'URL',
          name: 'qrCodeUrl',
          changeProp: 1,
          type: c.textType,
        }, {
          label: 'Farbe',
          name: 'qrCodeColor',
          changeProp: 1,
          type: c.colorInputType,
        }, {
          label: 'Breite',
          name: 'qrCodeWidth',
          changeProp: 1,
          type: c.numberType,
        }, {
          label: 'Höhe',
          name: 'qrCodeHeight',
          changeProp: 1,
          type: c.numberType,
        }],
        script() {
          const qrCodeUrl = '{[ qrCodeUrl ]}'
          const qrCodeColor = '{[ qrCodeColor ]}'
          const qrCodeWidth = '{[ qrCodeWidth ]}'
          const qrCodeHeight = '{[ qrCodeHeight ]}'

          const qrCodeElment = this
          const pattern = new RegExp('^(https?:\\/\\/)?' // protocol
            + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // domain name
            + '((\\d{1,3}\\.){3}\\d{1,3}))' // OR ip (v4) address
            + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // port and path
            + '(\\?[;&a-z\\d%_.~+=-]*)?' // query string
            + '(\\#[-a-z\\d_]*)?$', 'i') // fragment locator
          if (pattern.test(qrCodeUrl)) {
            const options = {
              width: qrCodeWidth || 300,
              height: qrCodeHeight || 300,
              type: 'svg',
              data: qrCodeUrl,
              dotsOptions: {
                color: qrCodeColor,
                type: 'rounded',
              },
            }
            if (!this.qr_code) {
              qrCodeElment.innerHTML = ''
              this.qr_code = new QRCodeStyling(options)
              this.qr_code.append(qrCodeElment)
            } else {
              this.qr_code.update(options)
            }
          } else {
            qrCodeElment.innerHTML = 'Bitte eine gültige URL angeben'
          }
        },
      },
    }, {
      isComponent(el) {
        if (el.getAttribute
          && el.getAttribute('data-gjs-type') === QR_CODE_TYPE) {
          return {
            type: QR_CODE_TYPE,
          }
        }
      },
    }),

    view: defaultView.extend({
      init() {
        this.listenTo(this.model, 'change:qrCodeUrl change:qrCodeColor change:qrCodeWidth change:qrCodeHeight', this.updateScript)
        const comps = this.model.get('components')

        // Add a basic qr-code template if it's not yet initialized
        if (!comps.length) {
          comps.reset()

          // comps.add('<span data-js="qr-code"><span data-js="qr-code-element"></span></span>')
        }
      },
    }),
  })
}
