<template>
  <div
    :class="`evo-config-container gjs-sm-sector gjs-sm-sector__general no-select ${opened ? 'gjs-sm-open' : ''}`"
  >
    <div
      class="gjs-sm-sector-title"
    >
      <div
        class="gjs-sm-sector-caret"
        @click="toggleView()"
      >
        <svg viewBox="0 0 24 24"><path
          fill="currentColor"
          d="M7,10L12,15L17,10H7Z"
        ></path></svg>
      </div>
      <div class="gjs-sm-sector-label d-flex justify-space-between w-100 align-center">
        <div
          class="w-100"
          @click="toggleView()"
        >
          {{ $t('Condition') }} {{ index + 1 }}
        </div>
        <div>
          <button
            type="button"
            class="gjs-pn-btn fa fa-minus-square"
            @click="removeItem()"
          >
          </button>
        </div>
      </div>
    </div>
    <div
      class="gjs-lg-properties"
      :style="`${opened ? '' : 'display: none;'} padding: 0 10px 10px;`"
    >
      <div class="gjs-lg-property gjs-lg-select gjs-sm-property__display">
        <div
          class="gjs-sm-label gjs-four-color"
          data-sm-label=""
        >
          <span
            class="gjs-sm-icon "
            title=""
          >
            {{ $t('Start') }}
          </span>
          <!-- <div
            class="gjs-sm-clear"
            style=""
            data-clear-style=""
          >
            <svg viewBox="0 0 24 24"><path
              fill="currentColor"
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"
            ></path></svg>
          </div> -->
        </div>
        <div
          class="gjs-fields"
          data-sm-fields=""
        >
          <div class="gjs-field gjs-select">
            <span id="gjs-sm-input-holder">
              <input
                v-model="startTime"
                type="time"
                :placeholder="$t('Start')"
              >
            </span>
          </div>
        </div>
      </div>
      <div class="gjs-lg-property gjs-lg-select gjs-sm-property__display">
        <div
          class="gjs-sm-label gjs-four-color"
          data-sm-label=""
        >
          <span
            class="gjs-sm-icon "
            title=""
          >
            {{ $t('End') }}
          </span>
          <!-- <div
            class="gjs-sm-clear"
            style=""
            data-clear-style=""
          >
            <svg viewBox="0 0 24 24"><path
              fill="currentColor"
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"
            ></path></svg>
          </div> -->
        </div>
        <div
          class="gjs-fields"
          data-sm-fields=""
        >
          <div class="gjs-field gjs-select">
            <span id="gjs-sm-input-holder">
              <input
                v-model="endTime"
                type="time"
                :placeholder="$t('End')"
              >
            </span>
          </div>
        </div>
      </div>
      <div class="gjs-lg-property gjs-lg-select gjs-sm-property__display">
        <div
          class="gjs-sm-label gjs-four-color"
          data-sm-label=""
        >
          <span
            class="gjs-sm-icon "
            title=""
          >
            {{ $t('From') }}
          </span>
        </div>
        <div
          class="gjs-fields"
          data-sm-fields=""
        >
          <div class="gjs-field gjs-select">
            <span id="gjs-sm-input-holder">
              <input
                v-model="startDate"
                type="date"
              >
            </span>
          </div>
        </div>
      </div>
      <div class="gjs-lg-property gjs-lg-select gjs-sm-property__display">
        <div
          class="gjs-sm-label gjs-four-color"
        >
          <span
            class="gjs-sm-icon "
          >
            {{ $t('Until') }}
          </span>
        </div>
        <div
          class="gjs-fields"
        >
          <div class="gjs-field gjs-select">
            <span id="gjs-sm-input-holder">
              <input
                v-model="endDate"
                type="date"
              >
            </span>
          </div>
        </div>
      </div>
      <div class="gjs-lg-property gjs-lg-select gjs-sm-property__weekdays">
        <div
          class="gjs-sm-label gjs-four-color"
        >
          <span
            class="gjs-sm-icon "
          >
            {{ $t('Weekday') }}
          </span>
        </div>
        <div
          class="gjs-fields"
        >
          <div class="gjs-field gjs-select">
            <span id="gjs-sm-input-holder">
              <v-combobox
                v-model="weekDay"
                :items="weekDays"
                item-text="label"
                item-value="value"
                class="evo-combobox"
                chips
                clearable
                :label="$t('Weekdays')"
                multiple
                solo
              >
                <template v-slot:selection="{ attrs, item, select, selected }">
                  <v-chip
                    v-bind="attrs"
                    :input-value="selected.value"
                    close
                    @click="select"
                    @click:close="remove(item)"
                  >
                    <strong>{{ item.label }}</strong>
                  </v-chip>
                </template>
              </v-combobox>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'AbsolutInput',
  props: {
    value: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    weekDays: {
      type: Array,
      required: true,
    },
    removeRow: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      opened: false,
      chips: [],
    }
  },
  computed: {
    startTime: {
      get() { return this.value.startTime },
      set(startTime) { this.$emit('input', { ...this.value, startTime }) },
    },
    endTime: {
      get() { return this.value.endTime },
      set(endTime) { this.$emit('input', { ...this.value, endTime }) },
    },
    startDate: {
      get() { return this.value.startDate },
      set(startDate) { this.$emit('input', { ...this.value, startDate }) },
    },
    endDate: {
      get() { return this.value.endDate },
      set(endDate) { this.$emit('input', { ...this.value, endDate }) },
    },
    weekDay: {
      get() { return this.value.weekDay },
      set(weekDay) { this.$emit('input', { ...this.value, weekDay }) },
    },
  },
  methods: {
    toggleView() {
      this.opened = !this.opened
    },
    remove(item) {
      this.value.weekDay.splice(this.value.weekDay.indexOf(item), 1)
      this.value.weekDay = [...this.value.weekDay]
    },
    removeItem() {
      this.removeRow(this.index)
    },
  },
}
</script>

<style lang="scss">
#gjs {
  height: 100vh !important;
}
.gjs-sm-property__weekdays {
  .v-text-field__details{
    display: none!important;
  }
  .v-input__slot {
    margin-bottom: 0;
  }
}
.evo-config-container {
  .v-input.evo-combobox input,
  .v-text-field--solo.evo-combobox > .v-input__control > .v-input__slot {
    background-color: #363636!important;
    color: #ddd!important;
  }
}
.w-100{
  width: 100%;
}
</style>
