/* eslint-disable default-case */
/* eslint-disable no-new */
/* eslint-disable no-param-reassign */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-plusplus */
/* eslint-disable consistent-return */
/* eslint-disable no-use-before-define */
/* eslint-disable no-sequences */
/* eslint-disable no-unused-expressions */
import Dropzone from 'dropzone'
import defaults from './config'
import VideoComponent from './videoComponent'

const css = require('./css/style')

// export default grapesjs ? grapesjs.plugins.add('grapesjs-video-embed-manager', (editor, options) => {
export default (editor, options = {}) => {
  // Add components
  // const domcv = editor.DomComponents.getType('video')
  // const dblclick = domcv.view.prototype.events.dblclick && domcv.view.prototype.events.dblclick !== 'onDblClick' ? domcv.view.prototype.events.dblclick : null
  // const oldEvent = domcv.view.prototype.events.dblclick ? domcv.view.prototype[dblclick] : null
  let component

  // // This is a little broken, but click events should never have more than an event passed
  // domcv.view.prototype.events.dblclick = 'onDblClick'

  // domcv.view.prototype.onDblClick = e => {
  //   // Video component normally doesn't have a double click event, so we should be safe
  //   component = editor.getSelected() || {}
  //   editor.runCommand('open-videos')

  //   // Just in case, call original event if this was one in setup
  //   if (oldEvent) domcv.view.prototype[dblclick](e)
  // }

  // Globals
  // Modal and current component
  let Modal

  // Universal options
  const opts = { ...defaults, ...options }

  // current page of query.
  let currentPage = 1

  // Html defaults
  let preloader; let error; let dataContainer; let container; let aside; let videoList; let footer; let
    pagination; let videoDropZone

  // pagination variables
  let query; let trailAmount; let totalPages; let
    totalVideos

  const largeTrailAmount = 10
  const mediumTrailAmount = 5
  const smallTrailAmount = 3

  // current selected resource and index of resource item
  let current; let
    currentIndex = 0

  // icons
  const firstPage = { d: 'M13,21,7,12l6-9H9L3,12l6,9Z  M21,21l-6-9,6-9H17l-6,9,6,9Z' }
  const lastPage = { d: 'M11,3l6,9-6,9h4l6-9L15,3Z  M3,3l6,9L3,21H7l6-9L7,3Z' }
  const nextPage = { d: 'M 8 3 L 14 12 L 8 21 L 12 21 L 18 12 L 12 3 L 8 3 z' }
  const previousPage = { d: 'M 13 3 L 7 12 L 13 21 L 17 21 L 11 12 L 17 3 L 13 3 z' }

  // Error animation timeout
  let timeout

  const hideError = () => {
    error.style.height = '0px'
    error.style.opacity = 0
    timeout = setTimeout(() => {
      error.style.display = 'none'
    }, 2000)
  }

  const showError = () => {
    clearTimeout(timeout)
    error.style.display = 'block'
    const h2 = error.querySelector('h2')

    error.style.height = `${h2.offsetHeight}px`
    error.style.opacity = 1
  }

  editor.on('component:update:jumpToNextPage', model => {
    if (model.changed && typeof model.changed.jumpToNextPage === 'boolean' && model.attributes.type === 'video') {
      model.attributes.attributes.jumpToNextPage = model.changed.jumpToNextPage
    }
  })

  // TODO: create a better listener
  editor.on('component:mount', model => {
    if (model.attributes.type === 'video') {
      component = model
      const videCom = new VideoComponent(model)
      videCom.init()

      // component.listenTo(component, 'change:videoId change:provider', () => {
      //   const prov = component.get('provider')
      //   if (![yt, ytnc, vi].includes(prov)) {
      //     const src = 'img/video2.webm'
      //     component.set({ src })

      //     component.em.trigger('component:toggled')
      //   }
      // })

      // editor.runCommand('open-videos')
    }
  })

  // TODO: create a better listener
  editor.on('component:add', model => {
    if (model.attributes.type === 'video') {
      component = model
      const videCom = new VideoComponent(model)
      videCom.init()

      // editor.runCommand('open-videos')
    }
  })

  editor.Commands.add('open-videos', {
    run() {
      // Add an event listener to make pagination readable on small screen sizes
      window.addEventListener('resize', setPaginationBind)

      return open()
    },
    stop() {
      // Remove event listender
      window.removeEventListener('resize', setPaginationBind)
      dataContainer.remove()
    },
  })

  editor.Commands.add('open-videos-webm', {
    run() {
      // Add an event listener to make pagination readable on small screen sizes
      window.addEventListener('resize', setPaginationBind)

      return openWebm()
    },
    stop() {
      // Remove event listender
      window.removeEventListener('resize', setPaginationBind)
      dataContainer.remove()
    },
  })
  const getVideoUrl = v => `${process.env.VUE_APP_BASE_URL_FILE}${v.source}`

  editor.Commands.add('insert-video', {
    run(e, s, o) {
      if (o.current === 'youtube') {
        component.set('provider', 'yt')
        component.set('videoId', o.id)

        return
      }

      if (o.current === 'vimeo') {
        component.set('provider', 'vi')
        component.set('videoId', o.id)

        return
      }

      if (o.current === 'local') {
        component.set('provider', 'so')
        component.set('src', getVideoUrl(o.video))
        component.set('poster', o.video.poster)
      }
    },

  })

  const init = () => {
    Modal = editor.Modal
  }

  const open = async () => {
    if (!Modal) init()

    const videoType = component && component.get('videoType') ? component.get('videoType') : 'mp4'
    if (videoType !== 'mp4') {
      component.set({ videoType: 'mp4' })
      component.set({ localData: null })
      opts.localData = null
    }

    await Modal.open({
      title: 'Video Manager',
      content: '',
      attributes: {
        id: 'video-modal',
      },
    })
      .onceOpen(renderHtml())
      .onceClose(() => editor.stopCommand('open-videos'))
  }
  const openWebm = async () => {
    if (!Modal) init()

    const videoType = component && component.get('videoType') ? component.get('videoType') : 'mp4'
    if (videoType === 'mp4') {
      component.set({ videoType: 'webm' })
      component.set({ localData: null })
      opts.localData = null
    }

    await Modal.open({
      title: 'Video Manager',
      content: '',
      attributes: {
        id: 'video-modal',
      },
    })
      .onceOpen(renderHtml(false))
      .onceClose(() => editor.stopCommand('open-videos-webm'))
  }

  /**
   * This is our master list of functions that run.
   * When a modal first opens, or when a page changes, these functions are run
   */
  const setData = () => {
    setVideos()
    setPagination()
    Modal.setContent(dataContainer)
  }

  const initDropZone = isMp4 => {
    const mimeType = isMp4 ? 'video/mp4' : 'video/webm'
    videoDropZone.innerHTML = `<form id="local-videos-uploader"  enctype="multipart/form-data" class="dropzone">
        <div id="gjs-am-title" class="dz-message needsclick">Datei hier ablegen oder durch Klick hochladend</div>
        <input type="file" id="gjs-am-uploadFile" class="gjs-video-upload-block" name="file" accept="${mimeType}">
        <div style="clear:both;"></div>
      </form>`
    const accessToken = localStorage.getItem('accessToken')
    const localDropZone = new Dropzone('#local-videos-uploader', {
      paramName: 'file', // The name that will be used to transfer the file
      maxFilesize: 200, // MB
      url: `${process.env.VUE_APP_BASE_URL}/grapejs/resources/${mimeType}`,
      acceptedFiles: mimeType,
      clickable: true,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    localDropZone.on('success', getVideos)
    localDropZone.on('successmultiple', getVideos)
  }

  // This should ideally be called once per page load
  const getInitialHtmlElements = () => {
    if (opts.preloader) {
      preloader = document.createElement('img')
      preloader.src = opts.preloader
      preloader.setAttribute('alt', 'Lädt...')
    } else {
      preloader = document.createElement('h2')
      preloader.innerHTML = 'Lädt...'
    }
    preloader.id = 'preloader'

    dataContainer = document.createElement('div')
    dataContainer.id = 'data-container'

    error = document.createElement('div')
    error.appendChild(document.createElement('h2'))
    error.className = 'video-error'
    error.onclick = hideError
    error.style.display = 'none'
    error.style.height = '0px'

    const style = document.createElement('style')
    style.innerHTML = css
    dataContainer.appendChild(style)

    aside = document.createElement('aside')
    aside.id = 'video-tabs'
    dataContainer.appendChild(aside)

    container = document.createElement('div')
    container.id = 'video-container'
    container.className = 'gjs-mdl-content'

    const videoContainer = document.createElement('div')
    videoContainer.className = 'gjs-am-assets-cont'

    videoList = document.createElement('ul')
    videoList.id = 'video-list'

    videoDropZone = document.createElement('div')
    videoDropZone.id = 'video-dropzone'
    videoDropZone.className = 'gjs-am-file-uploader'

    footer = document.createElement('footer')
    footer.id = 'video-paginate-footer'

    pagination = document.createElement('nav')
    pagination.id = 'video-paginate'
    footer.appendChild(pagination)

    videoContainer.appendChild(error)
    videoContainer.appendChild(videoList), container.appendChild(preloader), container.appendChild(videoDropZone), container.appendChild(videoContainer)
    dataContainer.appendChild(container), dataContainer.appendChild(footer)

    Modal.setContent(dataContainer)
  }

  const renderHtml = (isMp4 = true) => {
    getInitialHtmlElements()

    // If no resources were provided, we can't make any calls
    if (!opts.resources || !opts.resources.length) {
      return errorHandling(412, opts.resources, 'Sie haben keine Videoressourcen angegeben. Bitte konfigurieren Sie die Parameter "YouTube", "Vimeo", und/oder "Lokal" in den Optionen, um zu starten.')
    }

    // If there's more than one resource, create tabs to cycle through
    if (opts.resources.length > 1) {
      createTypeTabs(opts.resources)
    } else {
      aside.remove()
    }

    // Now set
    setType()

    initDropZone(isMp4)
  }

  const createTypeTabs = sources => {
    for (let i = 0; i < sources.length; i++) {
      const button = document.createElement('button')
      const span = document.createElement('span')
      button.className = 'tablinks gjs-pn-btn gjs-two-color', button.setAttribute('data-index', i)
      if (i === currentIndex) button.classList.add('gjs-four-color')
      span.innerHTML = sources[i][0].toUpperCase() + sources[i].substring(1)
      button.onclick = setType
      button.appendChild(span)

      aside.appendChild(button)
    }
  }

  const setType = (e = {}) => {
    // This is only called if a user clicks the type tab
    // Otherwise this function is called once
    if (e.currentTarget || e.target) {
      const button = e.currentTarget || e.target // browser failsafe
      button.classList.add('gjs-four-color')
      document.querySelector(`button.tablinks[data-index="${currentIndex}"]`).classList.remove('gjs-four-color')
      currentIndex = button.dataset.index
    }

    current = opts.resources[currentIndex]
    if (!current) return errorHandling(204, current, 'Diese Videoquelle scheint nicht zu existieren!')

    // Check if the manager already has data to be loaded
    // Also used for example purposes
    if (opts[`${current}Data`]) {
      showVideos()

      // Reminder, youtube page data has to be manually set
      currentPage = opts[`${current}Data`].page

      return setData()
    }

    return getVideos()
  }

  const getVideos = async () => {
    query = buildQuery()

    const loadUrl = opts[`${current}LoadUrl`]
    const callback = opts[`${current}LoadCallback`]
    showPreloader()

    const params = opts[`${current}Params`] ? opts[`${current}Params`] : {}
    params.headers = (opts[`${current}Headers`] ? opts[`${current}Headers`] : {})
    if (loadUrl) {
      return fetch(loadUrl + query, params)
        .then(async res => {
          let data = await res.json()

          if (res.ok) {
            if (current === 'youtube') data.page = currentPage
            if (opts[`${current}BeforeLoad`]) data = opts[`${current}BeforeLoad`](data)
            opts[`${current}Data`] = data
            showVideos()

            return setData()
          }

          try {
            return errorHandling(res.status, loadUrl + query, data.response.data.error.message)
          } catch (e) {
            throw new Error(res.statusText)
          }
        })
        .catch(err => errorHandling(500, loadUrl + query, err))
    }
    if (callback) {
      return handleCallback(callback, query, params)
    }

    return errorHandling(412, `${loadUrl + query}; ${callback}`, `Für die Ressource ${current} wurde kein Callback oder keine URL angegeben.`)
  }

  const handleCallback = async (callback, q, params) => {
    const call = await callback(q, params)

    if (typeof call !== 'object') return errorHandling(406, callback, 'Die Antwort des Callbacks ist ungültig')
    if (call.error) return errorHandling(error.status, callback, error.message)

    opts[`${current}Data`] = call
    showVideos()

    return setData()
  }

  const setVideos = () => {
    const data = opts[`${current}Data`]
    const videos = data.items || data.data || []
    videoList.innerHTML = ''

    if (videos.length > 0) {
      for (let i = 0; i < videos.length; i++) {
        createVideoThumb(videos[i], i)
      }
    } else {
      return errorHandling(411, data, 'Es sind keine Videos zur Darstellung vorhanden.')
    }
  }

  const buildQuery = () => {
    let q = ''
    const videoType = component && component.get('videoType') ? component.get('videoType') : 'mp4'
    if (opts.per_page) q += `${q.length ? '&' : '?'}per_page=${opts.per_page}`
    if (videoType) q += `${q.length ? '&' : '?'}video_type=${videoType}`

    if (opts[`${current}Data`] && (opts[`${current}Data`].nextPageToken || opts[`${current}Data`].prevPageToken)) {
      q += `${q.length ? '&' : '?'}page_token=${currentPage > opts[`${current}Data`].page ? opts[`${current}Data`].nextPageToken : opts[`${current}Data`].prevPageToken}`
    } else if (opts[`${current}Data`] && opts[`${current}Data`].page) {
      q += `${q.length ? '&' : '?'}page=${currentPage}`
    }

    return q
  }

  const createVideoThumb = (v, index) => {
    // Set video source
    const video = v
    const id = getVideoId(v)

    const img = document.createElement('video')
    const figure = document.createElement('figure')
    const imgLi = document.createElement('li')
    imgLi.setAttribute('class', `video-container video-empty video-${index}`), imgLi.setAttribute('data-loading', 'Lädt...'), imgLi.setAttribute('data-deleting', 'Löschen')

    // i.onload = () => {
    //   img.setAttribute('src', v.source)
    //   let srcset = {}

    //   // We are doing it this way to ensure the srcset is produced in the right order
    //   for (const name in thumbs) {
    //     const { width } = thumbs[name]
    //     srcset[width] = `${thumbs[name][url]} ${width}w`
    //   }

    //   srcset = Object.values(srcset).join(', ')
    //   img.setAttribute('srcset', srcset)

    //   // if (defaultThumb[url] && img.setAttribute("data-url", defaultThumb[url]), video.tag)
    //   // generate data setAttributeibutes
    //   for (const r in video) {
    //     if (video.hasOwnProperty(r) && r !== 'snippet') {
    //       if (typeof video[r] === 'object') {
    //         img.setAttribute(`data-${r}`, JSON.stringify(video[r]))
    //       } else {
    //         img.setAttribute(`data-${r}`, video[r])
    //       }
    //     }
    //   }

    //   img.onload = () => {
    //     imgLi.classList.remove('video-empty')
    //   }

    //   figure.appendChild(img)

    //   const title = video.title || video.name
    //   if (title) {
    //     img.setAttribute('alt', title)
    //     const figcaption = document.createElement('figcaption')
    //     figcaption.innerText = title
    //     figure.appendChild(figcaption)
    //   }
    // },
    // i.onerror = () => {
    //   imgLi.remove(), errorHandling(404, video)
    // },
    img.setAttribute('src', getVideoUrl(video))

    // set properties to query vid
    img.setAttribute('title', 'Einfügen')
    img.setAttribute('data-index', index)
    img.setAttribute('data-id', id)
    img.setAttribute('aria-role', 'button')
    img.setAttribute('style', 'width: 100px')
    img.onclick = insertVideo
    figure.appendChild(img)

    figure.classList.add('insert-video')
    const figcaption = document.createElement('figcaption')
    figcaption.innerText = video.title || video.name || ''
    figure.appendChild(figcaption)

    imgLi.appendChild(figure)
    videoList.appendChild(imgLi)
  }

  const insertVideo = e => {
    let index
    let id
    let returnObj
    if (e.currentTarget) {
      index = e.currentTarget.dataset.index
      id = e.currentTarget.dataset.id
    } else if (e.target) {
      index = e.target.dataset.index
      id = e.target.dataset.id
    }

    const data = opts[`${current}Data`]
    const videoObj = data.items ? data.items[index] : data.data[index]

    returnObj = {
      component,
      current,
      video: videoObj,
      id,
    }

    if (opts[`${current}BeforeInsert`]) returnObj = opts[`${current}BeforeInsert`](returnObj)
    if (!(index && id)) return errorHandling(409, returnObj, 'Das Video scheint keine ID zu haben und kann somit nicht in den Body eingefügt werden.')

    editor.runCommand('insert-video', returnObj)
    editor.stopCommand('insert-video')
    Modal.close()
  }

  const setPagination = () => {
    pagination.innerHTML = ''
    const videosData = opts[`${current}Data`]
    totalVideos = videosData.pageInfo ? videosData.pageInfo.totalResults : videosData.total

    totalPages = Math.ceil(totalVideos / opts.per_page)

    // How much trailing should exist in pagination is dependent if pages can be queried without a token
    // For sure youtube cannot trail pagination and can only be moved one page at a time
    // Local pagination is not set up this way, and I don't recommend setting up your server this way.
    setTrailAmount()

    if (totalPages > 1) {
      const ul = document.createElement('ul')
      ul.setAttribute('role', 'menubar')
      let r = document.createElement('li')
      r.setAttribute('aria-hidden', true), r.className = 'dots more-previous', r.innerHTML = '...'

      // Create first page chevron
      if (current !== 'youtube') ul.appendChild(createPaginateIcon('First Page', 1, 'page-nums chevrons first-chevrons', 1, 'video-paginate-icons', firstPage.d))
      ul.appendChild(createPaginateIcon('Previous Page', (currentPage - 1 <= 1 ? 1 : currentPage - 1), 'page-nums chevrons first-chevrons', (currentPage - 1 <= 1 ? 1 : currentPage - 1), 'video-paginate-icons', previousPage.d))
      ul.appendChild(r)

      // This is not setup to load on anything past page 1. May fix in the future.
      const elements = []

      // Pushing our current page...
      elements.push(createPaginateIcon(`Seite ${currentPage}`, currentPage, 'page-nums dynamic-pages', currentPage))

      let i = trailAmount
      let j = 0
      let addPrevious = 1
      let addNext = 1
      while (i > 0) {
        const prevPage = currentPage - addPrevious
        if (prevPage > 0) {
          elements.splice(0, 0, createPaginateIcon(`Seite ${prevPage}`, prevPage, 'page-nums dynamic-pages', prevPage))
          addPrevious++
          i--
        }

        const nP = currentPage + addNext
        if (nextPage <= totalPages) {
          elements.push(createPaginateIcon(`Seite ${nP}`, nP, 'page-nums dynamic-pages', nP))
          addNext++
          i--
        }

        // Failsafe break;
        j++
        if (j > trailAmount) break
      }

      elements.map(el => ul.appendChild(el))

      r = r.cloneNode(true)
      r.className = 'dots more-next'

      // Create last page chevron
      ul.appendChild(r)
      ul.appendChild(createPaginateIcon('Nächste Seite', (currentPage + 1 >= totalPages ? totalPages : currentPage + 1), 'page-nums chevrons last-chevrons', (currentPage + 1 >= totalPages ? totalPages : currentPage + 1), 'video-paginate-icons', nextPage.d))
      if (current !== 'youtube') ul.appendChild(createPaginateIcon('Letzte Seite', totalPages, 'page-nums chevrons last-chevrons', totalPages, 'video-paginate-icons', lastPage.d))

      pagination.appendChild(ul)
    } else {
      pagination.innerHTML = '<p style="text-align: center">Alle Ergebnisse</p>'
    }

    updatePaginationStyles()
  }

  // Ignore this. This is only here so the event listeners for resetting pagination on resize work
  const setPaginationBind = setPagination.bind(this)

  const createPaginateIcon = (ariaLabel, dataPage, liClasses, posinset, svgClasses = null, paths = null) => {
    const li = document.createElement('li')
    li.onclick = updatePage
    li.setAttribute('aria-label', ariaLabel)
    li.setAttribute('data-page', dataPage)
    li.className = liClasses
    const a = document.createElement('a')
    a.setAttribute('aria-posinset', posinset)

    if (svgClasses) {
      const svg = document.createElement('svg')
      svg.setAttribute('height', '100%')
      svg.setAttribute('width', '1.3em')
      svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg')
      svg.setAttribute('preserveaspectratio', 'xMidYMid meet')
      svg.setAttribute('viewBox', '0 0 24 24')
      svg.className = svgClasses
      const path = document.createElement('path')
      path.setAttribute('d', paths)
      svg.append(path)

      a.innerHTML = svg.outerHTML
    } else {
      a.innerHTML = dataPage
    }

    li.append(a)

    return li
  }

  const updatePaginationStyles = () => {
    footer.querySelectorAll('.page-nums').forEach(el => el.classList.remove('disabled-link'))
    footer.querySelectorAll('.dots').forEach(el => {
      el.style.display = 'none'
    })

    // Update the styles
    footer.querySelectorAll(`.page-nums[aria-label="Seite ${currentPage}"] a`).forEach(el => el.classList.add('disabled-link'))

    if (currentPage === 1) footer.querySelectorAll('.first-chevrons a').forEach(el => el.classList.add('disabled-link'))

    if ((currentPage + (trailAmount / 2)) < totalPages) {
      footer.querySelectorAll('.dots.more-next').forEach(el => {
        el.style.display = 'block'
      })
    }

    if ((currentPage - (trailAmount / 2)) > 1) {
      footer.querySelectorAll('.dots.more-previous').forEach(el => {
        el.style.display = 'block'
      })
    }

    if (currentPage === totalPages) footer.querySelectorAll('.last-chevrons a').forEach(el => el.classList.add('disabled-link'))
  }

  const updatePage = e => {
    const page = parseInt(e.currentTarget.dataset.page, 10)

    if (currentPage === page) return

    if (page) {
      currentPage = page
      query = buildQuery()

      getVideos()
    }
  }

  const setTrailAmount = () => {
    if (current === 'youtube') {
      trailAmount = 0
    } else if (window.innerWidth > 1200) {
      trailAmount = largeTrailAmount
    } else if (window.innerWidth > 600) {
      trailAmount = mediumTrailAmount
    } else {
      trailAmount = smallTrailAmount
    }
  }

  const getVideoId = video => {
    // youtube
    if (video.contentDetails) return video.contentDetails.videoId

    // local
    if (video.id) return video.id

    // vimeo
    if (video.uri) return (video.uri.split('/').pop())

    // uh oh
    return null
  }

  const errorHandling = (errorCode, item, message = null) => {
    if (message) {
      const h2 = error.querySelector('h2')
      h2.innerHTML = `<span style="display=inline-block; vertical-align:top; font-size: .8rem;">&#10006;</span> &nbsp;${errorCode} : ${message}`
      h2.focus()
      showError()
    }
  }

  const showVideos = () => {
    preloader.style.display = 'none'
    videoList.style.display = null
    videoDropZone.style.display = null
  }

  const showPreloader = () => {
    preloader.style.display = 'block'
    videoList.style.display = 'none'
    videoDropZone.style.display = 'none'
  }
}

// })
