/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable no-loop-func */
/* eslint-disable no-restricted-syntax */
/* eslint-disable arrow-body-style */
/* eslint-disable no-undef */
/* eslint-disable func-names */

import Quill from 'quill'

const css = require('./css/style')
require('quill/dist/quill.snow.css')

/* eslint-disable consistent-return */
export default function (editor, opt = {}) {
  const c = opt
  const domc = editor.DomComponents
  const defaultType = domc.getType('default')
  const defaultModel = defaultType.model
  const defaultView = defaultType.view
  const BUE_TERMINE_TYPE = 'bueTermine'
  let Modal
  let dataContainer = null
  let emptyTextContainer = null
  let error = null
  let aside = null
  let footer = null
  let container = null

  // let timeout = null

  // current selected resource and index of resource item
  // let current; let
  //   currentIndex = 0

  // // icons
  // const firstPage = { d: 'M13,21,7,12l6-9H9L3,12l6,9Z  M21,21l-6-9,6-9H17l-6,9,6,9Z' }
  // const lastPage = { d: 'M11,3l6,9-6,9h4l6-9L15,3Z  M3,3l6,9L3,21H7l6-9L7,3Z' }
  // const nextPage = { d: 'M 8 3 L 14 12 L 8 21 L 12 21 L 18 12 L 12 3 L 8 3 z' }
  // const previousPage = { d: 'M 13 3 L 7 12 L 13 21 L 17 21 L 11 12 L 17 3 L 13 3 z' }

  // // Error animation timeout
  const hideError = () => {
    error.style.height = '0px'
    error.style.opacity = 0
    timeout = setTimeout(() => {
      error.style.display = 'none'
    }, 2000)
  }

  // const showError = () => {
  //   clearTimeout(timeout)
  //   error.style.display = 'block'
  //   const h2 = error.querySelector('h2')

  //   error.style.height = `${h2.offsetHeight}px`
  //   error.style.opacity = 1
  // }
  window.BUE_TERMINE_BLOCK_RENDER = {
    table: elements => `
      <table class="w-100 h-100">
        <thead>
          <tr>
            ${elements.map(el => `<th>${el.label}</th>`).join('')}
          </tr>
        </thead>
        <tbody>
          <tr>
            ${elements.map(el => `<td data-xml-attribute="${el.dataId}"><${el.element}>${el.label}</${el.element}></td>`).join('')}
          </tr>
        </tbody>
      </table>`,
    card: elements => `<div class="card">
      <div class="container">
         ${elements.map(el => `<${el.element}  data-xml-attribute="${el.dataId}">${el.label}</${el.element}>`).join('')}
      </div>
    </div>`,
    list: elements => `<div class="rows">
    <div class="row">
      <div class="column" style="background-color:#aaa;">
        ${elements.map(el => `<${el.element}  data-xml-attribute="${el.dataId}">${el.label}</${el.element}>`).join('')}
      </div>
    </div>`,
  }
  domc.addType(BUE_TERMINE_TYPE, {

    model: defaultModel.extend({
      defaults: {
        ...defaultModel.prototype.defaults,
        contenteditable: false,
        layout: c.layout,
        relativVisibility: c.relativVisibility,
        activatePagination: c.activatePagination,
        perPage: c.perPage,
        dateRange: c.dateRange,
        bueTermineCss: c.bueTermineCss,
        pageInterval: c.pageInterval,
        showBullets: c.showBullets,
        contentConfig: c.contentConfig,
        apiUrl: c.apiUrl,
        apiHeaders: c.apiHeaders,
        emptyText: c.emptyText,
        disablePage: c.disablePage,
        css,
        droppable: false,
        traits: [{
          label: 'Layout',
          name: 'layout',
          changeProp: 1,
          type: 'select',
          options: [
            { value: '', name: 'Layout wählen' },
            { value: 'table', name: 'Tabelle' },
            { value: 'card', name: 'Karten' },
            { value: 'list', name: 'Liste' },
          ],
        }],
        'script-props': [
          'layout',
          'relativVisibility',
          'activatePagination',
          'perPage',
          'pageInterval',
          'showBullets',
          'contentConfig',
          'apiUrl',
          'apiHeaders',
          'css',
          'emptyText',
          'disablePage',
          'dateRange',
          'bueTermineCss',
        ],
        script(props) {
          const {
            layout,
            contentConfig,
            apiUrl,
            relativVisibility,
            activatePagination,
            perPage,
            css: contentCss,
            showBullets,
            pageInterval,
            emptyText,
            disablePage,
            dateRange,
            bueTermineCss,
          } = props
          let page = 1
          let totalPages = 1
          if (window.BUE_TERMINE_API_INTERVAL) {
            clearInterval(window.BUE_TERMINE_API_INTERVAL)
          }
          const elements = contentConfig.filter(o => o.visible).sort((a, b) => a.sort - b.sort)
          const filters = contentConfig.reduce((f, o) => {
            if (o.filter) {
              f[o.dataId] = o.filter
            }

            return f
          }, {})
          let termineCss = document.getElementById('bue-termine-css')
          if (!termineCss) {
            termineCss = document.createElement('style')
            termineCss.type = 'text/css'
            termineCss.id = 'bue-termine-css'
            document.head.appendChild(termineCss)
          }
          termineCss.innerHTML = bueTermineCss

          const render = {
            table: data => {
              return `
              <span style="padding:10px" id="preview-bue-termine">
              <table class="w-100 h-100">
                <thead>
                  <tr>
                    ${elements.map(el => `<th>${el.label}</th>`).join('')}
                  </tr>
                </thead>
                <tbody>
                  ${data.map(d => `<tr>
                    ${elements.map(el => `<td  data-xml-attribute="${el.dataId}"><${el.element}  data-xml-attribute="${el.dataId}-element">${d[el.dataId]}</${el.element}></td>`).join('')}
                  </tr>`).join('')}
                </tbody>
              </table> <span>`
            },
            card: data => {
              return `<div style="margin:10px; height: calc(100% - 50px); overflow: auto">
                <div class="card-container">
                    ${data.map(d => `<div class="card">
                    <div class="container">
                      ${elements.map(el => `<${el.element} data-xml-attribute="${el.dataId}">${el.label ? `${el.label}: ` : ''}${d[el.dataId]}</${el.element}>`).join('')}
                    </div>
                  </div>`).join('')}
                </div>
              </div>`
            },
            list: data => {
              return `<div style="margin:10px; height: calc(100% - 50px); overflow: auto">
                <div class="bue-termine-rows-listview">
                  ${data.map(d => `<div class="row">
                    <div class="column">
                      ${elements.map(el => `<${el.element}  data-xml-attribute="${el.dataId}">${el.label ? `${el.label}: ` : ''}${d[el.dataId]}</${el.element}>`).join('')}
                    </div>
                  </div>`).join('')}
                </div>
              </div>`
            },
            default: () => '<span style="margin:10px">Dieser Block benötigt Konfiguration</span>',
          }
          function rangePagination(current, m) {
            const last = m
            const delta = 6
            const left = current - delta
            const right = current + delta + 6
            const range = []
            const rangeWithDots = []
            let l

            for (let i = 1; i <= last; i++) {
              if (i === 1 || i === last || (i >= left && i < right)) {
                range.push(i)
              }
            }

            for (const i of range) {
              if (l) {
                if (i - l === 2) {
                  rangeWithDots.push(`<span ${(i === page) ? 'class="active"' : ''}></span>`)
                } else if (i - l !== 1) {
                  rangeWithDots.push('...')
                }
              }
              rangeWithDots.push(`<span ${(i === page) ? 'class="active"' : ''}></span>`)
              l = i
            }

            return rangeWithDots
          }
          const pagination = () => {
            if (activatePagination && showBullets) {
              const pages = rangePagination(page, totalPages)

              return `
                <div class="pagination-wrapper">
                <div class="pagination">
                  ${pages.join('')}
                </div>
              </div>
              `
            }

            return ''
          }
          const bueTermineElment = this
          if (bueTermineElment) {
            if (typeof render[layout] === 'function') {
              const body = {}
              body.page = page
              body.perPage = perPage
              body.dateRange = dateRange
              body.relativVisibility = relativVisibility
              body.activatePagination = activatePagination
              body.filters = filters
              const getData = b => {
                fetch(apiUrl, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(b),
                })
                  .then(response => response.json())
                  .then(data => {
                    if (data.data && Array.isArray(data.data) && data.data.length) {
                      totalPages = data.totalPages
                      bueTermineElment.innerHTML = `<style>${contentCss}</style> ${render[layout](data.data || [])} ${pagination()}`
                    } else if (page > 1) {
                      page = 1
                      body.page = page
                      getData(body)
                    } else if (disablePage && typeof window.GO_TO_NEXT_PAGE === 'function') {
                      if (window.BUE_TERMINE_API_INTERVAL) {
                        clearInterval(window.BUE_TERMINE_API_INTERVAL)
                      }
                      window.GO_TO_NEXT_PAGE()
                    } else {
                      bueTermineElment.innerHTML = emptyText
                    }
                  })
                  .catch(err => {
                    console.error('Error:', err)
                  })
              }
              getData(body)
              if (pageInterval && activatePagination) {
                window.BUE_TERMINE_API_INTERVAL = setInterval(() => {
                  if (totalPages > page) {
                    page += 1
                  } else {
                    page = 1
                  }
                  body.page = page
                  getData(body)
                }, pageInterval * 1000)
              }
            } else {
              bueTermineElment.innerHTML = `<style>${contentCss}</style> ${render.default()}`
            }
          }
        },
      },
    }, {
      isComponent(el) {
        if (el.getAttribute
          && el.getAttribute('data-gjs-type') === BUE_TERMINE_TYPE) {
          return {
            type: BUE_TERMINE_TYPE,
          }
        }
      },
    }),

    view: defaultView.extend({
      init() {
        Modal = editor.Modal
        const component = this.model
        const slist = target => {
          // (A) SET CSS + GET ALL LIST ITEMS
          target.classList.add('slist')
          const items = target.getElementsByTagName('li'); let
            current = null

          // (B) MAKE ITEMS DRAGGABLE + SORTABLE
          for (const i of items) {
            // (B1) ATTACH DRAGGABLE
            i.draggable = true

            // (B2) DRAG START - YELLOW HIGHLIGHT DROPZONES
            i.ondragstart = () => {
              current = i
              for (const it of items) {
                if (it !== current) { it.classList.add('hint') }
              }
            }

            // (B3) DRAG ENTER - RED HIGHLIGHT DROPZONE
            i.ondragenter = () => {
              if (i !== current) { i.classList.add('active') }
            }

            // (B4) DRAG LEAVE - REMOVE RED HIGHLIGHT
            i.ondragleave = () => {
              i.classList.remove('active')
            }

            // (B5) DRAG END - REMOVE ALL HIGHLIGHTS
            i.ondragend = () => {
              for (const it of items) {
                it.classList.remove('hint')
                it.classList.remove('active')
              }
            }

            // (B6) DRAG OVER - PREVENT THE DEFAULT "DROP", SO WE CAN DO OUR OWN
            i.ondragover = evt => { evt.preventDefault() }

            // (B7) ON DROP - DO SOMETHING
            i.ondrop = evt => {
              evt.preventDefault()
              if (i !== current) {
                let currentpos = 0
                let droppedpos = 0
                for (let it = 0; it < items.length; it++) {
                  if (current === items[it]) { currentpos = it }
                  if (i === items[it]) { droppedpos = it }
                }
                if (currentpos < droppedpos) {
                  i.parentNode.insertBefore(current, i.nextSibling)
                } else {
                  i.parentNode.insertBefore(current, i)
                }
                const sorting = {}
                for (let it = 0; it < items.length; it++) {
                  const item = items[it]
                  sorting[item.dataset.id] = it
                  item.dataset.sort = it
                }
                const contentConfig = BUE_TERMINE_COMPONENT.get('contentConfig').map(conf => {
                  conf.sort = sorting[conf.id]

                  return conf
                })
                BUE_TERMINE_COMPONENT.set('contentConfig', contentConfig)
                GRAPEJS_EDITOR.runCommand('update-bue-termine-layout', contentConfig)
                GRAPEJS_EDITOR.stopCommand('update-bue-termine-layout')
              }
            }
          }
        }
        const getModalContent = () => {
          dataContainer = document.createElement('div')
          dataContainer.id = 'data-container'

          error = document.createElement('div')
          error.appendChild(document.createElement('h2'))
          error.className = 'termine-error'
          error.onclick = hideError
          error.style.display = 'none'
          error.style.height = '0px'

          const style = document.createElement('style')
          style.innerHTML = css
          dataContainer.appendChild(style)

          aside = document.createElement('aside')
          aside.id = 'termine-tabs'
          dataContainer.appendChild(aside)
          window.BUE_TERMINE_COMPONENT = component
          container = document.createElement('div')
          container.id = 'termine-container'
          container.className = 'gjs-mdl-content'
          const scriptContainer = document.createElement('script')
          const previewContainer = document.createElement('div')
          const confContainer = document.createElement('div')
          previewContainer.className = 'w-60 h-100 preview'
          previewContainer.id = 'preview-bue-termine-modal'
          confContainer.className = 'w-40 h-100 conf'
          const contentConfig = component.get('contentConfig').sort((a, b) => a.sort - b.sort)
          scriptContainer.innerHTML = `
            function valueChanged(e, id, el = 'element'){
              e.parentElement.parentElement.parentElement.parentElement.dataset[el] = e.value
              const contentConfig = BUE_TERMINE_COMPONENT.get('contentConfig').map(c => {
                if(c.id === id) {
                  c[el] = e.value
                }
                return c
              })
              BUE_TERMINE_COMPONENT.set('contentConfig',contentConfig)
              GRAPEJS_EDITOR.runCommand('update-bue-termine-layout', contentConfig)
              GRAPEJS_EDITOR.stopCommand('update-bue-termine-layout')
            }

            function showHideElement(e, id) {
              // e.parentElement.parentElement.parentElement.parentElement.dataset.element = e.value
              if(e.dataset.visible === 'true') {
                e.classList.remove('fa-eye')
                e.classList.add('fa-eye-slash')
                e.dataset.visible = 'false'
              } else {
                e.classList.remove('fa-eye-slash')
                e.classList.add('fa-eye')
                e.dataset.visible = 'true'
              }
              e.parentElement.parentElement.dataset.visible = e.dataset.visible
              const contentConfig = BUE_TERMINE_COMPONENT.get('contentConfig').map(c => {
                if(c.id === id) {
                  c.visible = e.dataset.visible === 'true'
                }
                return c
              })
              BUE_TERMINE_COMPONENT.set('contentConfig',contentConfig)
              GRAPEJS_EDITOR.runCommand('update-bue-termine-layout', contentConfig)
              GRAPEJS_EDITOR.stopCommand('update-bue-termine-layout')
            }
          `
          container.appendChild(scriptContainer)
          const elements = [
            { label: 'Standard', value: 'p' },
            { label: 'Fett', value: 'b' },
            { label: 'Kursiv', value: 'i' },
            { label: 'H1', value: 'h1' },
            { label: 'H2', value: 'h2' },
            { label: 'H3', value: 'h3' },
            { label: 'H4', value: 'h4' },
          ]
          const inputStyle = 'color: #fff; background-color: #2c2c2c; padding: 10px 5px; width: 100%'
          confContainer.innerHTML = `
           <ul id="sortlist">
            ${contentConfig.map(conf => `
              <li data-id="${conf.id}" data-visible="${conf.visible}" data-sort="${conf.sort}" data-element="${conf.element}" data-label="${conf.label}" data-filter="${conf.filter}">
                <span class="w-40 gjs-input-holder" style="margin-right: 5px">
                  <input onkeyUp="valueChanged(this, ${conf.id}, 'label')" type="text" value="${conf.label}" style="${inputStyle}">
                </span>
                <span class="gjs-field-wrp gjs-field-wrp--select w-30" data-input="">
                  <span class="gjs-field gjs-field-select" style="padding: 5px; display: inherit">
                    <span data-input="">
                      <select value="${conf.element}" onChange="valueChanged(this, ${conf.id}, 'element')">
                        ${elements.map(e => ` <option ${e.value === conf.element ? 'selected' : ''} value="${e.value}">${e.label}</option>`).join('')}
                      </select>
                    </span>
                    <span class="gjs-sel-arrow">
                      <span class="gjs-d-s-arrow"></span>
                    </span>
                  </span>
                </span>
                <span class="w-10 flex-end">
                  <span title="Vorschau"  data-visible="${conf.visible}" class="gjs-pn-btn fa fa-eye${!conf.visible ? '-slash' : ''}" onClick="showHideElement(this, ${conf.id})"></span>
                </span>
                <span class="w-20 gjs-input-holder">
                  <input placeholder="filter" onkeyUp="valueChanged(this, ${conf.id}, 'filter')" type="text" value="${conf.filter}" style="${inputStyle}">
                </span>
              </li>
            `).join('')}
          </ul>
          `

          footer = document.createElement('footer')
          footer.id = 'termine-paginate-footer'

          container.appendChild(error)

          // container.appendChild(preloader)
          container.appendChild(previewContainer)
          container.appendChild(confContainer)
          dataContainer.appendChild(container)
          dataContainer.appendChild(footer)

          return dataContainer
        }
        const renderHtml = () => {
          Modal.setContent(getModalContent())
          slist(document.getElementById('sortlist'))
          const elements = BUE_TERMINE_COMPONENT.get('contentConfig').filter(el => el.visible).sort((a, b) => a.sort - b.sort)
          BUE_TERMINE_INSTANCE.updateScript()
          const previewCont = document.getElementById('preview-bue-termine-modal')
          if (
            previewCont
            && BUE_TERMINE_COMPONENT.get('layout')
            && typeof BUE_TERMINE_BLOCK_RENDER[BUE_TERMINE_COMPONENT.get('layout')] === 'function'
          ) {
            previewCont.innerHTML = BUE_TERMINE_BLOCK_RENDER[BUE_TERMINE_COMPONENT.get('layout')](elements)
          }
        }

        const open = async () => {
          if (!Modal) init()

          await Modal.open({
            title: 'BuE Termine',
            content: '',
            attributes: {
              id: 'bue-termine-modal',
            },
          })
            .onceOpen(renderHtml())
            .onceClose(() => {
              editor.stopCommand('open-bue-events')
            })
        }

        const renderEmptyHtml = () => {
          const emptyText = component.get('emptyText') || ''
          const disablePage = component.get('disablePage') || false

          emptyTextContainer = document.createElement('div')
          emptyTextContainer.id = 'bue-events-empty-text-container'

          const style = document.createElement('style')
          style.innerHTML = `
            .ql-toolbar.ql-snow, #bue-termine-empty-text {
                background-color: #fff;
            }
            #bue-events-empty-text-container footer {
              text-align: right;
              margin-top: 10px;
            }
         `
          emptyTextContainer.appendChild(style)
          const textEditor = document.createElement('div')
          textEditor.id = 'bue-termine-empty-text'
          textEditor.classList.add('w-100')
          textEditor.style.minHeight = '200px'
          textEditor.style.color = '#000'
          textEditor.innerHTML = emptyText
          emptyTextContainer.appendChild(textEditor)

          // checkbox
          const disablePageCheck = document.createElement('div')
          disablePageCheck.style = 'padding: 20px 0; border-bottom: 1px solid rgba(0,0,0,.2)'
          disablePageCheck.innerHTML = `
            <div class="gjs-trt-trait gjs-trt-trait--checkbox">
              <div class="gjs-label-wrp gjs-field-wrp--checkbox" style="width: 100%;min-width: 30px; display:flex;" data-input="">
                <label class="gjs-field gjs-field-checkbox" data-input="">
                <input type="checkbox" placeholder="" id="disable-page-when-no-termine" value="true" ${disablePage ? 'checked="checked"' : ''}>
                <i class="gjs-chk-icon"></i>
                </label>
                <label style="margin-left:10px" for="disable-page-when-no-termine" class="gjs-label" title="Diese Seite deaktivieren, wenn keine Termine verfügbar sind">
                  Diese Seite deaktivieren, wenn keine Termine verfügbar sind</label>
              </div>
            </div>
          `
          emptyTextContainer.appendChild(disablePageCheck)

          const emptyFooterModal = document.createElement('footer')
          emptyFooterModal.id = 'termine-paginate-footer'
          emptyFooterModal.innerHTML = `<button id="bue-termine-save-empty-text" class="gjs-btn-prim">Save</button>
          <button class="gjs-btn-sec" data-close-modal>Cancel</button>`
          emptyTextContainer.appendChild(emptyFooterModal)
          Modal.setContent(emptyTextContainer)
          // eslint-disable-next-line no-new
          setTimeout(() => {
            const quill = new Quill('#bue-termine-empty-text', {
              modules: {
                syntax: false,
                toolbar: [['bold', 'italic', 'underline'],
                  [{ list: 'bullet' }],
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],
                  [{ font: [] }],
                  [{ align: [] }]],
              },
              placeholder: 'Alternative Nachricht, wenn keine Termine verfügbar sind...',
              theme: 'snow',
            })
            quill.root.innerHTML = emptyText
            document.getElementById('bue-termine-save-empty-text').onclick = e => {
              e.preventDefault()
              component.set('emptyText', quill.root.innerHTML)
              component.set('disablePage', document.getElementById('disable-page-when-no-termine').checked)
              Modal.close()
            }
          }, 100)
        }
        const openEmptyText = async () => {
          if (!Modal) init()

          await Modal.open({
            title: 'BuE Termine',
            content: '',
            backdrop: false,
            attributes: {
              id: 'bue-termine-modal-empty',
              backdrop: false,
            },
          })
            .onceOpen(renderEmptyHtml())
            .onceClose(() => {
              editor.stopCommand('open-bue-events-empty')
            })
        }

        editor.Commands.add('open-bue-events-empty', {
          run() {
            // Add an event listener to make pagination readable on small screen sizes
            // window.addEventListener('resize', setPaginationBind)

            return openEmptyText()
          },
          stop() {
            // Remove event listender
            emptyTextContainer.remove()
            Modal.setContent('')
          },
        })

        editor.Commands.add('open-bue-events', {
          run() {
            // Add an event listener to make pagination readable on small screen sizes
            // window.addEventListener('resize', setPaginationBind)

            return open()
          },
          stop() {
            // Remove event listender
            // window.removeEventListener('resize', setPaginationBind)
            dataContainer.remove()
            Modal.setContent('')
          },
        })
        editor.Commands.add('update-bue-termine-layout', {
          run(e, s, o) {
            if (BUE_TERMINE_INSTANCE) {
              const elements = o.filter(el => el.visible).sort((a, b) => a.sort - b.sort)
              BUE_TERMINE_INSTANCE.updateScript()
              const previewCont = document.getElementById('preview-bue-termine-modal')
              if (
                previewCont
                && BUE_TERMINE_COMPONENT.get('layout')
                && typeof BUE_TERMINE_BLOCK_RENDER[BUE_TERMINE_COMPONENT.get('layout')] === 'function'
              ) {
                previewCont.innerHTML = BUE_TERMINE_BLOCK_RENDER[BUE_TERMINE_COMPONENT.get('layout')](elements)
              }
            }
          },

        })
        const getLayoutTrait = layout => {
          const traits = [
            {
              label: 'Layout',
              name: 'layout',
              changeProp: 1,
              type: 'select',
              options: [
                { value: '', name: 'Layout wählen' },
                { value: 'table', name: 'Tabelle' },
                { value: 'card', name: 'Karten' },
                { value: 'list', name: 'Liste' },
              ],
            },
          ]
          if (layout || c.layout) {
            traits.push(
              {
                type: 'button',
                text: 'Verwalten',
                full: true,
                command: 'open-bue-events',
              },
              {
                type: 'button',
                text: 'Hinzufügen',
                label: 'Alternative Nachricht, wenn keine Termine verfügbar sind',
                full: true,
                command: 'open-bue-events-empty',
              },
              {
                type: 'checkbox',
                label: 'Zeitbasierte Sichtbarkeit',
                name: 'relativVisibility',
                changeProp: 1,
              },
              {
                type: 'checkbox',
                label: 'Automatische Paginierung einrichten',
                name: 'activatePagination',
                changeProp: 1,
              },
            )
            const ap = this.model.get('activatePagination')
            if (component.getTraits().length < 2 && !ap) {
              this.listenTo(this.model, 'change:activatePagination', () => {
                const tr = getLayoutTrait(layout)
                const activatePagination = this.model.get('activatePagination')
                const traitNames = tr.map(t => t.name)
                if (activatePagination || c.activatePagination) {
                  if (!traitNames.includes('perPage')) {
                    tr.push({
                      type: 'number',
                      label: 'Elemente pro Seite',
                      name: 'perPage',
                      changeProp: 1,
                    })
                  }
                  if (!traitNames.includes('pageInterval')) {
                    tr.push({
                      type: 'number',
                      label: 'Seitenwechsel nach (Sek.)',
                      name: 'pageInterval',
                      changeProp: 1,
                    })
                  }
                  if (!traitNames.includes('showBullets')) {
                    tr.push({
                      type: 'checkbox',
                      label: 'Bullets anzeigen',
                      name: 'showBullets',
                      changeProp: 1,
                    })
                  }
                  if (!traitNames.includes('dateRange')) {
                    tr.push({
                      type: 'number',
                      label: 'Datumsbereich',
                      name: 'dateRange',
                      changeProp: 1,
                    })
                  }
                }
                if (!traitNames.includes('bueTermineCss')) {
                  tr.push({
                    type: 'css-textarea',
                    label: 'CSS',
                    name: 'bueTermineCss',
                    changeProp: 1,
                  })
                }
                component.set({ tagName: BUE_TERMINE_TYPE }, { silent: 1 }) // avoid break in view
                component.set({ traits: tr })
                component.em.trigger('component:toggled')
              })
            } else if (ap) {
              const traitNames = traits.map(t => t.name)
              if (!traitNames.includes('perPage')) {
                traits.push({
                  type: 'number',
                  label: 'Elemente pro Seite',
                  name: 'perPage',
                  changeProp: 1,
                })
              }
              if (!traitNames.includes('pageInterval')) {
                traits.push({
                  type: 'number',
                  label: 'Seitenwechsel nach (Sek.)',
                  name: 'pageInterval',
                  changeProp: 1,
                })
              }
              if (!traitNames.includes('showBullets')) {
                traits.push({
                  type: 'checkbox',
                  label: 'Bullets anzeigen',
                  name: 'showBullets',
                  changeProp: 1,
                })
              }
              if (!traitNames.includes('dateRange')) {
                traits.push({
                  type: 'number',
                  label: 'Datumsbereich',
                  name: 'dateRange',
                  changeProp: 1,
                })
              }
              if (!traitNames.includes('bueTermineCss')) {
                traits.push({
                  type: 'css-textarea',
                  label: 'CSS',
                  name: 'bueTermineCss',
                  changeProp: 1,
                })
              }
            }
          }

          return traits
        }
        window.BUE_TERMINE_INSTANCE = this
        this.listenTo(this.model, 'change:layout', () => {
          this.updateScript()
          const layout = this.model.get('layout')
          const traits = getLayoutTrait(layout)
          component.set({ tagName: BUE_TERMINE_TYPE }, { silent: 1 }) // avoid break in view
          component.set({ traits })
          component.em.trigger('component:toggled')
        })

        component.set({ tagName: BUE_TERMINE_TYPE }, { silent: 1 }) // avoid break in view
        component.set({ traits: getLayoutTrait(this.model.get('layout')) })
        component.em.trigger('component:toggled')
        const comps = this.model.get('components')

        // Add a basic bueTermine template if it's not yet initialized
        if (!comps.length) {
          comps.reset()

          // comps.add('<span style="margin: 10px" data-js="bueTermine"></span>')
        }
      },
    }),
  })
}
